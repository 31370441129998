import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';

import ItemWithImage from './ItemWithImage.web';
import { CustomSectionContainer, SubcatContainer } from './CustomContainer.web';

import { IContentData, ISectionData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const webStyles = {
  wrapRowLayout: {
    flexDirection: 'row', 
    flexWrap: 'wrap', 
    gap: { xs: 5, md: 2.5 }
  }
}

export default function OpioidMyths({ data }: { data: IContentData }) {
  const { attributes } = data;
  const { terms } = attributes;

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  return (
    <CustomSectionContainer
      containerStyle={{ mb: '-20px', py: { xs: 2.5, md: 5 } }} 
      contentStyle={webStyles.wrapRowLayout}
    >   
      {sectionList.map((section, index) => 
        <SubcatContainer
          key={index}
          withHeading
          heading={parse(section.name)}
          containerStyle={{ my: 0, flexBasis: { xs: '100%', md: 0 } }}
        >
          {section.children && section.children.length > 0 && section.children.map((item, index) => 
            <ItemWithImage 
              key={index} 
              image={item.images.length > 0 ? item.images[0]?.url : ''} 
              text={parse(item.definition)} 
              imageSize={24}  
            />)}
        </SubcatContainer>)}
    </CustomSectionContainer>
  );
}
