import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import {  hospiceLogoExample } from "./assets";

export interface FormRegistrationValues {
  email: string,
  password: string,
}
interface ResponseJson {
  meta: {
    token: string;
  };
}
export interface FormInformationValues {
  hospiceName: string,
  contactNumber: string,
  website: string,
  license: string,
  address: string,
  city: string,
  selectedState: string,
  postalCode: string,
  hospiceLogo: File | null,
  agreement: boolean
}
import  {  createRef } from 'react';
import {  FormikProps } from 'formik';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  registrationStepIndex: number,
  isPasswordVisible: boolean,

  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  loading: boolean;
  isOpenModalInformation: boolean;
  videoUrl: string;
  windowSize: number;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  listOfStates: string[];
  mapOfRegexes: Record<keyof FormRegistrationValues | keyof FormInformationValues, RegExp>;
  initialRegistrationFormValues: FormRegistrationValues;
  initialInformationFormValues: FormInformationValues;
  formikRef = createRef<FormikProps< { email: string; password: string;  }>>();

  labels: Record<keyof FormRegistrationValues | keyof FormInformationValues, string>;
  placeholders: Record<keyof FormRegistrationValues | keyof FormInformationValues, string>;
  validationErrors: Record<keyof FormRegistrationValues | keyof FormInformationValues, string>;
  buttonsText: Record<string, string>;
  signUpHeader: string;
  agreementText: string;
  andText: string;
  termsAndConditionsLabel: string;
  privacyPolicyLabel: string;

  hospiceLogoExample: string;

  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  editInformationApiCallId: string = "";
  fetchingVideoApiCallId: string = "";

  btnTextSignUp: string;

  currentCountryCode: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      registrationStepIndex: 0,
      isPasswordVisible: false,

      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      loading: false,
      isOpenModalInformation:false,
      videoUrl: "",
      windowSize: window.innerWidth
      // Customizable Area End
    };

    // Customizable Area Start
    this.listOfStates = configJSON.listOfStates;
    this.mapOfRegexes = configJSON.mapOfRegexes;
    this.initialRegistrationFormValues = {
      email: "",
      password: "",
    }
    this.initialInformationFormValues = {
      hospiceName: "",
      contactNumber: "",
      website: "",
      license: "",
      address: "",
      city: "",
      selectedState: "",
      postalCode: "",
      hospiceLogo: null,
      agreement: false
    }

    this.labels = configJSON.labels;
    this.placeholders = configJSON.placeholders;
    this.validationErrors = configJSON.validationErrors;
    this.buttonsText = configJSON.buttonsText;
    this.signUpHeader = configJSON.signUpHeader;
    this.agreementText = configJSON.agreementText;
    this.andText = configJSON.andText;
    this.termsAndConditionsLabel = configJSON.termsAndConditionsLabel;
    this.privacyPolicyLabel = configJSON.privacyPolicyLabel;
    this.hospiceLogoExample = hospiceLogoExample;

    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");
    this.btnTextSignUp = configJSON.btnTextSignUp;
    this.formikRef = createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        this.handleFetchingVideoMessage(apiRequestCallId, message)
        this.handleEditInformationMessage(apiRequestCallId, message)
        this.handleCreateAccountMessage(apiRequestCallId, message)
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    window.addEventListener("resize", this.handleResize)
    this.fetchVideoMedia()
    const tokenRegister =  sessionStorage.getItem("register_step_token")
    if(tokenRegister){
      this.setState({
        registrationStepIndex: this.state.registrationStepIndex + 1,
        otpAuthToken: tokenRegister,
      });
      sessionStorage.removeItem("login_success_message")
    }
  }
  handleResize = () => {
    const windowSize = window.innerWidth;
    this.setState({ windowSize });
  };
  fetchVideoMedia = () => {
    const header = { "Content-Type": configJSON.validationApiContentType };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchingVideoApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.fetchingVideoApiCallId = requestMessage.messageId;
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleCreateAccountMessage = (apiRequestCallId: string, message:Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (apiRequestCallId === this.createAccountApiCallId) {
      if (!responseJson.errors) {
        if (this.state.registrationStepIndex < 1) {
          this.setState({
            registrationStepIndex: this.state.registrationStepIndex + 1,
            otpAuthToken: responseJson.meta.token,
            loading: false,
          });
        }
      } else {
        this.setState({loading: false})
        this.formikRef.current?.setErrors({email:responseJson.errors[0].password})
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  handleEditInformationMessage = (apiRequestCallId: string, message:Message) => {
    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    var errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (apiRequestCallId === this.editInformationApiCallId) {
      if (!responseJson.errors) {
        this.saveLoggedInUserData(responseJson)
        this.props.navigation.replace("Subscriptionbilling2")
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  handleFetchingVideoMessage = (apiRequestCallId: string, message:Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.fetchingVideoApiCallId) {
      if (responseJson.data) {
        const data = responseJson.data;
        const attributes = (data && data.length > 0 && data[0]?.attributes) || {};
        this.setState({ videoUrl: attributes.video_file || '' })
      }
    }
  }
  isStringNullOrBlank(){
    this.setState({email: ""})
  }
  saveLoggedInUserData(responseJson: ResponseJson) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const messageLogin: Message = new Message(getName(MessageEnum.SessionSaveMessage));
      localStorage.setItem("user_data", JSON.stringify(responseJson))
      localStorage.setItem("token", responseJson.meta.token)
      localStorage.setItem("current_role", 'hospice')
      localStorage.setItem("main_flow", 'hospice')
      messageLogin.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      messageLogin.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(messageLogin);
    }
  }

  togglePasswordVisibility = () => {
    this.setState({ isPasswordVisible: !this.state.isPasswordVisible });
  }

  onRegistrationFormSubmit = (values: FormRegistrationValues) => {
    this.createAccount(values.email, values.password)
  }
  onInformationFormSubmit = (values: FormInformationValues) => {
    const formData = new FormData();
    formData.append("data[hospice_name]", values.hospiceName)
    formData.append("data[website]", values.website)
    formData.append("data[license]", values.license);
    formData.append("data[city]", values.city)
    formData.append("data[state]", values.selectedState)
    formData.append("data[postal_code]", values.postalCode)
    formData.append("data[address]", values.address)
    formData.append("data[phone_number]", values.contactNumber)
    formData.append("data[onboard_status]", 'true')
    if(!!values.hospiceLogo){
      formData.append("data[hospice_logo]", values.hospiceLogo)
    }
    this.editInformation(formData)
  }

  goToPrivacyPolicy = () => {
    const goMessage: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    goMessage.addData(getName(MessageEnum.TermsAndConditionsTypeMessage), "Privacy Policy");
    goMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(goMessage);
  }

  goToTermsAndCondition = () => {
    const passMessage: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    passMessage.addData(getName(MessageEnum.TermsAndConditionsTypeMessage), "Terms and Conditions");
    passMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(passMessage);
  }

  createAccount = (email: string, password: string): boolean => {
    this.setState({loading: true})
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      email,
      password,
      activated: true
    };

    const data = {
      type: "hospice",
      attributes: attrs
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  editInformation = (formData: FormData): boolean => {
    const header = {
      token: this.state.otpAuthToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editInformationApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeEdit
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  btnSignUpProps = {
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };
  goToLogin = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }
  handleCloseInformationModal = () => {
    this.setState({
      isOpenModalInformation: false
    })
  }
  handleOpenInformationModal = () => {
    this.setState({
      isOpenModalInformation: true
    })
  }
  // Customizable Area End
}
