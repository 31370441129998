import React from "react";
// Customizable Area Start
import { Box, Typography, Grid } from "@mui/material";
import {
  styled,
} from "@mui/material/styles";
import AuthorizedHeader from "../../../../packages/components/src/AuthorizedHeader";
import AuthorizedFooter from "../../../../packages/components/src/AuthorizedFooter";
import ContactHospice from "../../../../packages/components/src/ContactHospice";
import Loader from "../../../../packages/components/src/Loader";
import CategoryBreadcrumb from "../../../../packages/components/src/CategoryBreadcrumb";
import CategoriessubcategoriesController, {  Props, configJSON  } from "./CategoriessubcategoriesController";
export interface ICategory {
  id: string
  type: string
  attributes: {
    id: number
    name: string
    has_sub_categories: boolean
    has_content: boolean
    content_id: string
    image: {
      id: number
      url: string
    }
  }
}

const webStyles = {
  hoveredCategoryContainer: { 
    "&:hover": {
      background: "#444E41",
      cursor: 'pointer',
    },
    "&:hover .text-category": {
      color: "#fff",
    }
  } 
}
// Customizable Area End

export default class Catalogue extends CategoriessubcategoriesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderLoader = () => {
    return (
      <Loader loading={this.state.loading} />
    )
  }
  renderBreadcrumb = () => {
    if (this.state.fullResponseData === "") return <></>
    return <CategoryBreadcrumb navigation={this.props.navigation} data={this.state.fullResponseData} />
  }
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <Container>
        {this.renderLoader()}
        <PageContainer>
          <LayoutContainer>
            <div style={{display: 'flex', flexDirection: 'column', width: "100%"}}>
              <AuthorizedHeader navigation={this.props.navigation} data-test-id={"authorized-header"} />
              {this.renderBreadcrumb()}
              {this.state.isShowNurseContact && 
                <NurseContact>
                  <ContactHospice type="nurse" />
                </NurseContact>}
            </div>
            <Grid container columnSpacing={{ xs: 0, md: '26px' }} rowSpacing={{ xs: 1.25, md: '26px' }} justifyContent="center">
              {this.state.categoriesArray.map((item: ICategory, index: number) =>  
                <Grid item key={index.toString()} xs={4} md={3} lg={2} sx={{ display: 'flex' }}>
                  <CategoryContainer 
                    data-test-id="category-item" 
                    sx={[!this.isNotClickable(item.attributes.has_sub_categories, item.attributes.has_content) && webStyles.hoveredCategoryContainer]} 
                    onClick={() => this.handleClickCategory(item)}
                  >
                    <CategoryContent>
                      <ImageContainer>
                          <Image src={item.attributes.image.url}/>
                      </ImageContainer>
                      <CardText className="text-category">{item.attributes.name}</CardText>
                    </CategoryContent>
                  </CategoryContainer>
                </Grid>
              )}
            </Grid>
            <AuthorizedFooter
              footerFirstSentence={configJSON.footerFirstSentenceText}
              footerSecondSentence={configJSON.footerSecondSentenceText}
              navigation={this.props.navigation}
              data-test-id={"authorized-footer"}
            />
          </LayoutContainer>
        </PageContainer>
      </Container>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const CategoryContainer = styled(Box)({
  display: "flex",
  flexDirection: 'column',
  justifyContent: 'flex-start',
 border: "1px solid #D6DCD5", 
  width: "100%",
  height: 'calc(100% - 10px)',
  borderRadius: 8,
  "@media (max-width: 900px)": {
    // width: "114px", height: "160px",
    border: "none"
  },
});
const CategoryContent = styled("div")({
  display: "flex", flexDirection: 'column', flex: 1,alignItems: 'center',  justifyContent: 'flex-start', padding: "20px 0px 20px 0px", rowGap: "5px",
  "@media (max-width: 900px)": {
    padding: "0px 5px 0px 5px",
  },
});
const ImageContainer = styled("div")({
  display: 'flex', justifyContent: 'center', alignItems: 'center', width: 130, height: 130, borderRadius: 130, border: "0.78px solid #7C8E7680", background: '#F2F4F1',
  "@media (max-width: 900px)": {
    width: 80, height: 80,
  },
});
const Image = styled("img")({
  width: 80, height: 80,
  "@media (max-width: 900px)": {
    width: 47, height: 47,
  },
});
const CardText = styled(Typography)({
  fontFamily: "Lato", fontWeight: 500, fontSize: 20, textAlign: 'center', color: "#343C32", paddingLeft: 10, paddingRight: 10,
  "@media (max-width: 900px)": {
    fontSize: 14,
    marginBottom: 10,
    paddingLeft: 0, paddingRight: 0
  },
});
const PageContainer = styled(Box)({
  flex: 1,
  display: "flex",
  backgroundColor: "#FAF9F6",
  flexDirection: "column",
  "@media (max-width: 900px)": {
    backgroundColor: "#fff",
  },
});
const Container = styled(Box)({
  width: "100%", height: "100%", display: "flex",
  backgroundColor: "#FAF9F6",
  overflowY: 'scroll',
  "@media (max-width: 900px)": {
    backgroundColor: "#fff",
  },
});
const NurseContact = styled(Box)({
  width: "80%",
  alignSelf: 'center',
  "@media (max-width: 900px)": {
    width: "94%",
  },
});
const LayoutContainer = styled(Box)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  rowGap: "30px",
  boxSizing: "border-box",
  padding: "20px 20px 14px 20px",
  "@media (max-width: 900px)": {
    padding: "0px 0px 0px 0px",
  },
  alignItems: "center",
});

// Customizable Area End
