import React, { useEffect, useState } from "react";
import { Box, Link } from "@mui/material";
import { styled } from '@mui/system';
import parse, { domToReact, Element, HTMLReactParserOptions } from "html-react-parser";

import OverviewSection from "./OverviewSection.web";
import PlainTextItem from "./PlanTextItem.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";

import { IContentData, ISectionData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const webStyles = {
  alertContainer: {
    border: '2px dashed #F87171', 
    backgroundColor: '#FEF2F2', 
    paddingY: '20px'
  }
}

const LightSectionTitle = styled(Box)({
  fontWeight: '700',
  fontFamily: 'Lato',
  color: '#586554',
  '& p': {
    margin: 0
  }
});

const DiscussionTopics = ({ data }: { data: IContentData }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const parsingOptions: HTMLReactParserOptions = {
    trim: true,
    replace(domNode) {
      if (!(domNode instanceof Element)) return;
      const replacementByName: { [key: string]: JSX.Element } = {
          "ul": <>{domToReact(domNode.children, parsingOptions)}</>,
          "li": <PlainTextItem style={{ p: 1.25 }}>{domToReact(domNode.children)}</PlainTextItem>,
          "a": (
            <Link 
              href={domNode.attribs['href']} 
              target="_blank"
              sx={{ color: '#343C32', textDecorationColor: '#343C32' }}
            >
              {domToReact(domNode.children)}
            </Link>
          ),        
          "p": <LightSectionTitle mt={2.5} sx={{ fontSize: { xs: '20px', md: '26px' } }}>{domToReact(domNode.children)}</LightSectionTitle>
      };
      if (!replacementByName[domNode.name]) return;
      return replacementByName[domNode.name];
    },
  }

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          id: group[1][0].id,
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  return (
    <SectionListLayout styles={{ gap: '10px' }}>
      <OverviewSection 
        images={title?.images.map(image => image.url) || []} 
        name={parse(title?.title)} 
        shortDesc={title?.desc && title?.desc !== '' && parse(title?.desc)}
      />
      {sectionList.map((section, index) => 
          <CustomSectionContainer
            key={index}
            withHeading
            heading={parse(section.name)}
            containerStyle={[index === sectionList.length - 1 && webStyles.alertContainer]}
            headingStyle={{ px: { xs: 1.25, md: 2.5 } }}
            contentStyle={{ px: { xs: 1.25, md: 2.5 }, gap: 0 }}
          >
            {parse(section.definition, parsingOptions)}
          </CustomSectionContainer>
      )}
    </SectionListLayout>
  );
}

export default DiscussionTopics;
