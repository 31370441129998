import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { SxProps } from '@mui/system';
import { Stack } from '@mui/material';

interface Props {
  open: boolean,
  handleCloseModal: () => any,
  headerTitle: string,
  renderContent: () => any,
  textButtonLeft: string,
  textButtonRight: string,
  handleClickButtonLeft?: () => void
  handleClickButtonRight: () => void
  loading: boolean,
  height?: number,
  containerStyle?: SxProps,
  contentStyle?: SxProps
  footerStyle?: SxProps
}
export default function CustomModal(props: Props) {
  return (
    <Modal
      open={props.open}
      onClose={props.handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack 
        direction="column" 
        sx={[
          webStyles.modalContainer,
          ...(Array.isArray(props.containerStyle) ? props.containerStyle : [props.containerStyle]),
        ]}
      >
        <Typography style={webStyles.headerTitle}>{props.headerTitle}</Typography>
        <Stack 
          direction="column" 
          sx={[
            { border: '1px solid #D6DCD5', p: { xs: '40px 20px', md: 5 } },
            ...(Array.isArray(props.contentStyle) ? props.contentStyle : [props.contentStyle]),
          ]}
        >
          {props.renderContent()}
        </Stack>
        <Stack
          direction="row" 
          sx={[
            webStyles.buttonModalContainer,
            { pr: { xs: 0, md: 2 }, justifyContent: { xs: 'center', md: 'flex-end' } },
            ...(Array.isArray(props.footerStyle) ? props.footerStyle : [props.footerStyle]),
          ]} 
        >
          <Button
            data-test-id={"cancel-send-email-button"}
            onClick={props.handleClickButtonLeft ??  props.handleCloseModal}
            style={{ backgroundColor: '#fff', borderColor: "#D6DCD5", borderWidth: 1, borderStyle: 'solid', minWidth: 120, fontFamily: "Lato", color: "#586554" ,  textTransform: "none", fontWeight: 700, fontSize: 16, borderRadius: 8   }}
          >
            {props.textButtonLeft}
          </Button>
          <Button
            data-test-id={"send-email-button"}
            onClick={props.handleClickButtonRight}
            variant='contained'
            style={{ backgroundColor: '#7C8E76', minWidth: 120, fontFamily: "Lato", color: "#fff", textTransform: "none", fontWeight: 700, fontSize: 16, borderRadius: 8  }}
          >
            {props.textButtonRight}
          </Button>

        </Stack>
      </Stack>
    </Modal>
  );
}
const webStyles = {
  modalContainer: {
    background: "#fff",
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    borderRadius: '8px',
    borderBottomRightRadius: '32px',
    '@media (max-width: 899px)': {
      top: 'auto',
      bottom: '0',
      left: '0',
      width: '100%',
      transform: 'none',
      borderRadius: 0,
      borderTopRightRadius: '32px'
    }
  },
  buttonModalContainer: {
    alignItems: 'center',
    paddingY: '30px',
    gap: '10px'
  },
  headerTitle: {
    fontFamily: "Lato",
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '32px',
    marginTop: "24px",
    marginBottom: "24px",
    marginLeft: "40px",
    color: "#343C32"
  },
  modalTextContent: {
    fontSize: 16,
    fontFamily: "Lato",
    color: "#000"
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    borderRadius: 4,
    padding: 20,
    background: '#FFFFFF'
  },
}