import React from "react";
// Customizable Area Start
import { Stack, Typography } from "@mui/material";
import Box from '@mui/material/Box';
import { Input, Button } from '@builder/component-library';
import MaterialButton from '@mui/material/Button';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import {imgSearch, imgAddButton, imgMail, imgEdit, infoIcon, headerTable, emptyImage, emptyListImage, sortIcon} from "./assets"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';
import Toast from "../../../components/src/Toast";
import HospiceHeader from "../../../components/src/HospiceHeader";
import ModalConfirm from "../../../components/src/ModalConfirm";
import StripeListener from "../../../components/src/StripeListener";
import Loader from "../../../components/src/Loader";
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderHeaderSearch = () => (
    <Box sx={{ px: { xs: 2, md: 0 } }}>
      <Stack sx={[
        webStyles.headerContainer, 
        { 
          mb: { xs: 2.5, md: 1.25 }, 
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: { xs: 'center', md: 'space-between' },
          alignItems: 'center',
          gap: { xs: 1.25, md: 0 },
          py: { xs: 1.5, md: 2.5 } 
        }
      ]}>
        <Stack sx={{ flexDirection: { xs: 'column', md: 'row' }, gap: { xs: 1.25, md: 2.5 }, justifyContent: { xs: 'center', md: 'flex-start' }, alignItems: 'center' }}>
          <Typography sx={[webStyles.textTitle, { fontSize: { xs: '22px', md: '24px' } }]}>
            {webConfigJSON.listOfPatents}
          </Typography>
          <Stack direction="row" sx={{ gap: 1, alignItems: 'center' }}>
            <IOSSwitch onChange={this.handleChangeSwitch} defaultChecked />
            <Typography style={{fontSize: 16, fontFamily: "Inter", color: "#586554"}}>{webConfigJSON.hideExpired}</Typography>
          </Stack>
        </Stack>
        <Stack 
          direction="row" 
          sx={{ display: { xs: 'none', md: 'flex' }, flex: 1, gap: 2.5, alignItems: 'center', justifyContent: 'flex-end' }}
        >
          <Input 
            data-test-id="search-input" 
            onChangeText={this.onChangeTextInput} 
            containerStyle={{ borderRadius: 100, maxWidth: 397, width: '100%' }} 
            leftIcon={<img src={imgSearch} style={{ width: 24, height: 24 }}/>} 
            placeholder={webConfigJSON.placeHolderSearch}
          />
          <Button 
            onPress={this.handleNavigatePinGenerator} 
            text={webConfigJSON.btnAddPatient}
            icon={<img src={imgAddButton} style={{width: 24, height: 24}}/>}
            iconPosition="left"
            data-test-id="button-add-patient"
            textStyle={{fontFamily: "Lato", color: "#fff" }}
            style={{backgroundColor: '#7C8E76' }}
          />
        </Stack>
        <Stack 
          direction="row" 
          sx={{ display: { xs: 'flex', md: 'none' }, width: '100%', gap: 2.5, alignItems: 'center' }}
        >
          <Input 
            data-test-id="search-input" 
            onChangeText={this.onChangeTextInput} 
            containerStyle={{ borderRadius: 100, width: 'calc(100% - 64px)' }} 
            leftIcon={<img src={imgSearch} style={{ width: 24, height: 24 }}/>} 
            placeholder={webConfigJSON.placeHolderSearch}
            textStyle={{ fontSize: 14 }}
          />
          <Button 
            onPress={this.handleNavigatePinGenerator} 
            icon={<img src={imgAddButton} style={{ width: 24, height: 24 }}/>}
            data-test-id="button-add-patient"
            style={{ backgroundColor: '#7C8E76' }}
          />
        </Stack>
      </Stack>
    </Box>
  )
  renderStatus = (status: boolean) => {
    if(status === true){
      return <div style={{padding: 2,
        width: 90,
        background: '#D1FAE5', borderRadius: 100
      }}>
          <Typography style={{fontFamily: 'Lato', textAlign: 'center', fontWeight: '700', color: '#059669'
          }}>Active</Typography>
      </div>
    }
    return <div style={{padding: 2,
      width: 90,
      background: '#FEE2E2', borderRadius: 100
    }}>
        <Typography style={{fontFamily: 'Lato', fontWeight: '700',textAlign: 'center', color: '#DC2626'
        }}>Expired</Typography>
    </div>
  }
  renderTableHeader = (item: { id: string; text: string; note?: string; width: string, sortBy?: string }) => {
    const renderSortedButton = () => {
      if (item.sortBy){
        return <div data-test-id={`sort-button-${item.id}`} style={{ cursor: 'pointer' }}
      onClick={() => this.requestSort(item.sortBy ?? "")}
      >
        <img src={sortIcon} style={{ width: 24, height: 24 }} />
      </div>
      }
    }
    const renderHeaderContent = () => {
      return <Typography sx={{ fontFamily: 'Lato', fontSize: { xs: '14px', md: '16px' }, fontWeight: '700', lineHeight: '22px', color: "#343C32" }}>
        {item.text}
        {item.note && <img style={{ width: 14, height: 14, marginBottom: -2, marginLeft: 3 }} src={infoIcon} />}
      </Typography>
    }
    if (!item.note) {
      return <StyledTableCell sx={{ width: item.width }} key={item.id} align="left">
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          {renderHeaderContent()}
          {renderSortedButton()}
        </div>
      </StyledTableCell>
    }
    return <StyledTableCell sx={{ width: item.width }} key={item.id} align="left">
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography style={{ fontFamily: 'Lato', fontSize: 16, fontWeight: '700', color: "#343C32" }}>
          {item.text}
          <MaterialButton data-test-id={`header-${item.id}`} sx={{ all: 'unset', cursor: 'pointer' }}
            onMouseLeave={this.handleMouseLeaveHeader} onMouseEnter={(e) => this.handleHoverHeader(e, item.id)}>
            <img style={{ width: 14, height: 14, marginBottom: -2, marginLeft: 3 }} src={infoIcon} />
          </MaterialButton>
        </Typography>
        {renderSortedButton()}
      </div>
    </StyledTableCell>
  }
  renderModalResendEmail = () => (
    <ModalConfirm
      data-test-id="modal-resend-email"
      open={this.state.isOpenModalResendEmail}
      handleCloseModal={this.handleCloseModalResendEmail}
      headerTitle={webConfigJSON.headerModalTitle}
      renderFirstDes={() => <Typography style={webStyles.modalTextContent}>{webConfigJSON.modalFirstLine}{" "}<span style={{...webStyles.modalTextContent, fontWeight: '700'}}>{this.state.caregiverSelected?.caregiver_name}</span>?</Typography>}
      secondLineDes={webConfigJSON.modalSecondLine}
      textButtonLeft="No, Cancel"
      textButtonRight="Yes, Send Email"
      loading={this.state.loading}
      handleClickButtonRight={this.handleResendEmail}
      containerStyle={{
        '@media (max-width: 899px)': { 
          width: '100%',
          bottom: 0,
          left: 0,
          top: 'auto',
          transform: 'translate(0, 0)', 
          borderRadius: '0 32px 0 0',
        },
      }}
    />
  )
  renderPopover = () => {
    return    <Popper
    sx={{ zIndex: 1200, marginTop: 20 }}
    open={this.state.isOpenPopoverInfo}
    anchorEl={this.state.anchorEl}
    placement='bottom-start'
    transition
    data-test-id={`header-note-header`}
  >
    {({ TransitionProps }) => (
      <Fade {...TransitionProps} timeout={350}>
        <Paper>
          <div style={{ padding: 6, paddingLeft: 10, paddingRight: 10,background: '#444E41', width: 170, borderRadius: 5
           }}>
          <Typography sx={{ fontSize: 12, color: "#fff", fontFamily: "Lato" }}>{headerTable[parseInt(this.state.currentHoverId) - 1].note}</Typography>
          </div>
        </Paper>
      </Fade>
    )}
  </Popper>
  }
  renderTableRows = () => {
   return  this.getSortedData().map((patient) => {
     return patient.caregivers.map((caregiver, caregiverIndex) => {
      const rowSpan = patient.caregivers.length
      const spanCell = caregiverIndex === 0
        return <TableRow
        key={caregiver.caregiver_id.toString()}
        data-test-id="patient-item"
        sx={{ fontSize: { xs: '12px', md: '14px' }, lineHeight: { xs: '14.4px', md: '16.8px' } }}
      >
        {spanCell && <StyledTableCell sx={{width: "87px"}}  rowSpan={rowSpan}  component="th" scope="row">
          {patient.no}
        </StyledTableCell>}
        {spanCell && <StyledTableCell sx={{width: "142px"}} rowSpan={rowSpan}  align="left">{patient.patient_name}</StyledTableCell>}
        {spanCell && <StyledTableCell  sx={{width: "132px"}}  rowSpan={rowSpan} align="left">{this.renderStatus(patient.patient_status)}</StyledTableCell>}
        <StyledTableCell sx={{width: "13vw"}}  align="left">{caregiver.caregiver_name}</StyledTableCell>
        <StyledTableCell sx={{width: "9vw"}}  align="left">{caregiver.relation_to_patient}</StyledTableCell>
        <StyledTableCell sx={{width: "145px"}}  align="left">{caregiver.no_of_devices}</StyledTableCell>
        <StyledTableCell sx={{width: "10vw"}}  align="left">{this.handleContactNumber(caregiver.caregiver_phone_number)}</StyledTableCell>
        <StyledTableCell sx={{width: "225px"}}  align="left">{caregiver.caregiver_email}</StyledTableCell>
        <StyledTableCell sx={{width: "9vw"}}  align="left">
        <Button
                data-test-id="resend-button"
                style={webStyles.removeBackground}
                icon={<img src={imgMail} style={{width: 24, height: 24}}/>}
                onPress={() => {
                  this.handleOpenModalResendEmail(caregiver)
                }}
              />
          </StyledTableCell>
        {spanCell && <StyledTableCell sx={{width: "57px"}}  rowSpan={rowSpan}  align="left">
        <Button
                data-test-id={"edit-button"}
                style={webStyles.removeBackground}
                icon={<img src={imgEdit} style={{width: 24, height: 24}}/>}
                onPress={() => {this.handleEditPatient(patient.patient_id.toString())}}
              />
          
          </StyledTableCell>}
      </TableRow>
      })
    })
  }
  renderEmptyTable = () => {
    return <div style={{display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <img src={emptyImage} style={{width: 260, height: 260, marginBottom: 6}}/>
        <LatoText sx={{ color: '#A7B3A3', textAlign: 'center', fontSize: { xs: '16px', md: '28px' } }}>Begin by adding patients to your account!</LatoText>
        <Button onPress={this.handleNavigatePinGenerator} text={webConfigJSON.btnAddPatient}
      icon={<img src={imgAddButton} style={{width: 24, height: 24}}/>}
      iconPosition="left"
      data-test-id="button-add-patient"
       textStyle={{fontFamily: "Lato", color: "#fff" }}
       style={{backgroundColor: '#7C8E76', marginTop: 20 }}
       />
    </div>
  }
  renderEmptyList = () => {
    return <div style={{display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', background: "#fff"}}>
        <img src={emptyListImage } style={{width: 202, height: 238, marginBottom: 6}}/>
        <LatoText sx={{ textAlign: 'center', color: '#A7B3A3', fontSize: { xs: '16px', md: '28px' }, fontWeight: '700' }}>No patients found</LatoText>
    </div>
  }
  renderTable = () => {
    if (this.state.originDashboardData.length === 0 && !this.state.loading ) return this.renderEmptyTable()
    if (this.state.dashboardData.length === 0 && !this.state.loading ) return this.renderEmptyList()
    return (
      <Stack direction='column' sx={{ flex: 1, px: { xs: 2, md: 0 } }}>
        <TableContainer component={Paper}>
          <Table data-test-id="table-patient-data" sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {headerTable.map(item => {
                  return this.renderTableHeader(item)
                })}
                <StyledTableCell sx={{width: "57px"}} align="left"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.renderTableRows()}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    )
  }
  renderToast = () => {
    return (
      <Toast 
        data-test-id="toast-message" 
        textMessage={this.state.successMessage} 
        isShow={this.state.isShowToast} 
        width={401}
        top={40}
      />
    )
  }
  renderLoader = () => {
    return (
      <Loader loading={this.state.loading}/>
    )
  }
  renderHeader = () => {
    return   <HospiceHeader
    navigation={this.props.navigation}
    data-test-id={"authorized-header"}
  />
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <Stack 
        direction="column" 
        sx={{ bgcolor: { xs: '#FFF', md: '#F2F2F2' }, p: { xs: 0, md: 2.5 }, flex: 1 }}
      >
        <StripeListener handleUpdate={this.handleUpdateUserSubscription} handleSubmit={this.handleCreateUserSubscription}/>
        {this.renderLoader()}
        {this.renderHeader()}
        {this.renderPopover()}
        {this.state.originDashboardData.length > 0 && this.renderHeaderSearch()}
        {this.renderModalResendEmail()}
        {this.renderTable()}
        {this.renderToast()}
      </Stack>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  pageContainer: {
    height: "100%", 
    flex: 1, 
  },
  modalContainer: {
    background: "#fff",
    display: "flex",
    position: 'absolute' as 'absolute',
    height: 320,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    borderRadius: 8
  },
  buttonModalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: "flex-end",
    paddingTop: 20,
    paddingRight: 10
  },
  headerTitle: {
    fontFamily: "Lato",
    fontSize: 24,
    fontWeight: '700',
    marginTop: "24px",
    marginBottom: "24px",
    marginLeft: "40px",
    color: "#000"
  },
  modalContent: {
    border: '1px solid #D6DCD5',
    padding: "40px",
    display: 'flex',
  },
  modalTextContent: {
    fontSize: 16,
    fontFamily: "Lato",
       color: "#000"
  },
  headerContainer: {
    marginTop: 2.5,
    borderRadius: 4,
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.08)',
    paddingX: 2.5,
    background: '#FFFFFF',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textTitle: {
    color: "#343C32",
    fontWeight: '700',
    fontFamily: 'Lato',
  },
  removeBackground: {
    backgroundColor: "transparent" ,
    width: 24,
    height: 24
  },
  searchContainer: {
    display: 'flex',
    flexDirection: "row",
    borderWidth: 1, borderRadius: 100,
    padding: 10,
    paddingLeft: 8,
    paddingRight: 8 
  }
};
const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 48,
  height: 28,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#7C8E76',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#7C8E76',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:theme.palette.grey[100]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 24,
    height: 24,
  },
  '& .MuiSwitch-track': {
    borderRadius: 28 / 2,
    backgroundColor: '#D6DCD5',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 12,
  lineHeight: '14.4px',
  fontWeight: 500,
  fontFamily: "Lato",
  color: "#586554",
  '@media (min-width: 900px)': { fontSize: 14, lineHeight: '16.8px' },
  [`&.${tableCellClasses.head}`]: {
    borderRight: `1px solid #7C
    return <StyledTableCell sx={{ width: item.width }} key={item.id} align="left">8E7633`
  },
  [`&.${tableCellClasses.body}`]: {
    borderRight: `1px solid #7C8E7633`
  },
}));
const LatoText = styled(Typography)({
  fontFamily: "Lato",
  color: "#343C32",
  fontWeight: "400",
  fontSize: 28
});
// Customizable Area End
