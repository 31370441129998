import React, { useEffect, useState, useRef } from "react";
import { styled } from "@mui/system";
import { Box, Typography, Grid, Button, Checkbox } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import AuthorizedFooter from './AuthorizedFooter';
import Toast from './Toast';
import ReactHtmlParser from './ReactHtmlParser';
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas';


import { IContentData } from "../../blocks/contentmanagement/src/ContentManagementController";
const downloadImage = require('./download_icon.svg');
const resetImage = require('./reset_icon.svg');

interface IEnvironmentItem {
  id: number,
  text: string,
  image: string,
  checked: boolean,
}
  const HomeEnvironment = ({ data, navigation }: { data: IContentData, navigation: any }) => {
  const { attributes } = data;
  const { title, terms } = attributes;
  const pdfRef = useRef(null)



  const [sectionList, setSectionList] = useState<IEnvironmentItem[]>([]);
  const [toastMessage, setToastMessage] = useState('')
  const [display, setDisplay] = useState('none')
  const [downloadingPdf, setDownloadingPdf] = useState(false)

  useEffect(() => {
    if (terms) {
      const homeEnvironmentString =  localStorage.getItem("home_environment") ?? ""
      let homeEnvironment: string[] = []
      if(homeEnvironmentString !== ""){
        homeEnvironment = JSON.parse(homeEnvironmentString)
      }
      terms.sort((a, b) => a.id - b.id); 
      const listData: IEnvironmentItem[] = terms.map(term => {
       return {
        id: term.id,
        text: term.definition,
        image: term.images[0].url,
        checked: homeEnvironment.includes(term.id.toString())
       }
      })
      setSectionList(listData)
    }
  }, [terms]);
  const renderSectionText = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const listItems = Array.from(doc.querySelectorAll('p')).map(p => p.innerHTML);
    return ReactHtmlParser(listItems[0])
  }
  const handleDownloadPdf = () => {
    setDownloadingPdf(true)
    setDisplay("flex")
    setTimeout(() => {
      const inputData = pdfRef.current!
      html2canvas(inputData, {useCORS: true})
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF();
          const width = pdf.internal.pageSize.width
          const height = (canvas.height * width) / canvas.width
          pdf?.addImage(imgData, 'PNG', 0, 0, width, height);
          pdf.save("download.pdf");
          setDisplay("none")
          setDownloadingPdf(false)
          showModalSuccess("Checklist downloaded")
        }).finally(() => {
          setDownloadingPdf(false)
        })
      ;
    }, 1000);
   
  }
  const handleCheckItem = (itemId: number, isChecked: boolean, index: number) => {
    let listSectionTemp = [...sectionList]
    const homeEnvironmentString =  localStorage.getItem("home_environment") ?? ""
    let homeEnvironment: string[] = []
    if(homeEnvironmentString !== ""){
      homeEnvironment = JSON.parse(homeEnvironmentString)
    }
    if(isChecked){
      homeEnvironment.push(itemId.toString())
    }else{
     homeEnvironment = homeEnvironment.filter((home) => home !== itemId.toString())
    }
    listSectionTemp = listSectionTemp.map(section => {
      if(section.id === itemId){
        return {
          ...section,
          checked: isChecked
        }
      }
      return section
    })
    setSectionList(listSectionTemp)
    localStorage.setItem("home_environment", JSON.stringify(homeEnvironment))
  }
  const handleResetAll = () => {
    let listSectionTemp = [...sectionList]
    listSectionTemp = listSectionTemp.map(section => {
      return {
        ...section,
        checked: false
      }
    })
    showModalSuccess("Checklist reset")
    setSectionList(listSectionTemp)
    localStorage.removeItem("home_environment")
  }

  const renderTemplateExport = () => {
    return <div style={{ padding: "12px", position: "absolute", zIndex: -500, display: display }} ref={pdfRef}> <div style={{ width: '756px', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
      <div style={{ width: '756px', height: "90px", borderRadius: "12px", background: "#7C8E76", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img style={{ width: '145px', height: "62px" }} src={require('./group_logo.png')} />
      </div>
      <Typography style={{ color: "#DC2626", fontFamily: 'Lato', fontWeight: "700", fontSize: '24px', textTransform: 'uppercase', marginTop: "10px" }}>Home environment and safety</Typography>
      <img style={{ width: "398px", height: "190px", objectFit: 'contain' }} src={title.images[0].url} />
      <div style={{ width: '756px', height: "56px", borderRadius: "8px", background: "#F2F4F1", display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "10px" }}>
        <Typography style={{ color: "#586554", fontFamily: 'Lato', fontWeight: "400", fontSize: '20px', textTransform: 'uppercase', }}>Check off these items to ensure a safe household</Typography>
      </div>
      <div  style={{ marginTop: "10px", width: '756px', display: 'flex', flexDirection: 'row', flexWrap: "wrap", gap: "12px" }} >
        {sectionList.map((section, index) => 
          <div style={{ display: "flex", flexDirection: "row", alignItems: 'center', columnGap: '20px', width: (index === sectionList.length - 1 && sectionList.length % 2 === 1) ? "756px" : "372px", border: "1px solid #D6DCD5", borderRadius: "8px",   paddingTop: "10px",
  paddingBottom: "10px",
  paddingLeft: "20px",
  paddingRight: "20px",  boxSizing: "border-box"}} >
    <img style={{width: "20px", height: "20px"}} src={require('./empty_check.svg')}/>
          
          <img style={{ width: "59px", height: "59px", objectFit: 'contain' }} src={section.image} />
          <Typography style={{ fontFamily: "Lato", fontSize: "16px", color: "#343C32" }}>{renderSectionText(section.text)}</Typography>
        </div>
        )}
      </div>
    </div>
    </div>
  }
  const renderToast = () => {
    return (
      <Toast onClose={() => {setToastMessage('')}}  data-test-id="popup-message" textMessage={toastMessage} isShow={toastMessage !== ""} width={213} top={100}/>
    )
  }
  const showModalSuccess = (message: string) => {
    setToastMessage(message)
    setTimeout(() => {
      setToastMessage("")
    }, 3000);
  }
  return (
    <>
    {renderTemplateExport()}
      <Container>
    {renderToast()}
        <ButtonContainer>
          <PrimaryButton loading={downloadingPdf} loadingPosition="center" onClick={handleDownloadPdf} startIcon={<img src={downloadImage}/>}>
          Print Friendly Version
          </PrimaryButton>
          <SecondaryButton onClick={handleResetAll} startIcon={<img src={resetImage}/>}>
          Reset All
          </SecondaryButton>
        </ButtonContainer>
        <ContentContainer >
          <Header>
            <TextHeader>{renderSectionText(title.desc)}</TextHeader>
          </Header>
          <Grid container sx={{marginTop: "6px"}} alignItems="stretch" spacing={2}>
            {sectionList.map((section, index) => <Grid sx={{display: 'flex'}} key={section.id.toString()} item xs={12} md={(index === sectionList.length - 1 && sectionList.length % 2 === 1) ? 12 :6}> <EnvironmentItem >
              <Checkbox sx={{  color: '#7C8E76', 
        '&.Mui-checked': {
          color: '#7C8E76', 
        },
        '&:hover': {
          borderColor: '#7C8E76', 
        },
        '& .MuiSvgIcon-root': {
          borderRadius: '6px', 
        },}} onChange={(e) => {
                handleCheckItem(section.id, e.target.checked, index)
              }
              } checked={section.checked}/>
              <ContentItem>
              <EnvironmentImageItem src={section.image} />
              <EnvironmentTextItem key={index}>{renderSectionText(section.text)}</EnvironmentTextItem>

              </ContentItem>
            </EnvironmentItem>
            </Grid>
            )}
          </Grid>
          <BottomImage src={title.images[0].url}/>
        </ContentContainer>
        <AuthorizedFooter
                footerFirstSentence="Not familiar with a term?"
                footerSecondSentence=" Click here to find it in our dictionary."
                navigation={navigation}
                data-test-id={"authorized-footer"}
              />
      </Container>
     
    </>
  );
}
const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 0,
  '@media (max-width: 900px)' : {
   marginBottom: -20
    }
});
const EnvironmentItem = styled(Box)({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingLeft: "20px",
  paddingRight: "20px",
  borderRadius: 8,
  background: "#FFF",
  border:  "1px solid #D6DCD5",
  gap: "20px",
  boxSizing: "border-box",
  
})
const EnvironmentImageItem = styled("img")({
  width: '80px',
  height: "80px",
  marginRight: '20px',
  '@media (max-width: 900px)' : {
    width: '60px',
    height: "60px",
    marginRight: '0px',
    }
})
const BottomImage = styled("img")({
  marginTop: '20px',
  marginBottom: "35px",
   height: "380px",
  objectFit: 'contain',
  aspectRatio: 380/780,
  '@media (max-width: 900px)' : {
    height: "173px",
    marginBottom: "20px",
    }
})

const EnvironmentTextItem = styled(Typography)({
  fontFamily: "Lato",
  fontWeight: "400",
  fontSize: "22px",
  color: "#343C32",
  '@media (max-width: 900px)' : {
    fontSize: "16px",
    }
})
const ContentContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '100px',
  paddingRight: '100px',
  marginTop: '40px',
  '@media (max-width: 900px)' : {
    fontSize: "14px",
    paddingLeft: '20px',
    paddingRight: '20px',
    marginTop: '30px',
    }
});
const ContentItem = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '@media (max-width: 900px)' : {
    rowGap: "12px",
    flexDirection: 'column',
    alignItems: 'flex-start'
    }
});
const Header = styled(Box)({
  borderRadius: '8px',
  width: '100%',
  boxSizing: 'border-box',
  paddingTop:  '20px',
  paddingBottom: "20px",
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: "#F2F4F1",
  textAlign: 'center',
  '@media (max-width: 900px)' : {
 paddingLeft: "12px",
 paddingRight: "12px"
  }
})
const TextHeader = styled(Typography)({
  fontSize: "26px",
  fontFamily: "Lato",
  color: "#586554",
  fontWeight: "400",
  '@media (max-width: 900px)' : {
  fontSize: '20px'
  }
})
const ButtonContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gap: "12px",
  '@media (max-width: 899px)' : {
    justifyContent: 'center',
  }
});

const PrimaryButton = styled(LoadingButton)({
  backgroundColor: "#7C8E76",
  height: "44px",
  paddingLeft: "20px",
  paddingRight: "20px",
  borderRadius: "8px",
  fontFamily: "Lato",
  fontSize: "16px",
  fontWeight: 700,
  color: "#FFFFFF",
  textTransform: "capitalize",
  "&:hover": {
      cursor: "pointer",
      backgroundColor: "#7C8E76",
      color: "#FFFFFF",
  },
  '@media (max-width: 899px)' : {
    fontSize: "14px"
    },
    '& .MuiLoadingButton-loadingIndicator': {
      color: '#fff',
    }
});
const SecondaryButton = styled(Button)({
  backgroundColor: "#FFFFFF",
  height: "44px",
  paddingLeft: "20px",
  paddingRight: "20px",
  borderRadius: "8px",
  fontFamily: "Lato",
  fontSize: "16px",
  fontWeight: 700,
  color: "#586554",
  border: "1px solid #D6DCD5",
  textTransform: "capitalize",
  marginRight: "80px",
  '@media (max-width: 900px)' : {
    fontSize: "14px",
    marginRight: "0px",
    }
});
export default HomeEnvironment;
