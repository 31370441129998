import React from 'react';
import { Typography, Box, IconButton, Button } from '@mui/material';
import { styled } from '@mui/system';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { IBreadcrumb, Type } from '../../blocks/contentmanagement/src/ContentManagementController';

const InlineFlexContainer = styled(Box)({
  display: "inline-flex",
  alignItems: "center",  
});

const MainContainer = styled(Box)(({ theme }) => ({ 
  marginTop: "10px", 
  marginBottom: "10px",
  display: "flex", 
  alignItems: 'center',
  gap: 0,
  [theme.breakpoints.up("lg")]: {
    gap: "10px",
    minWidth: '512px',
  },
}));

const BreadcrumbElement = styled(Button)(({ theme }) => ({
  padding: '4px 8px',
  borderRadius: '6px',
  '&:hover': {
    backgroundColor: '#F1F5F9',
  },
  textTransform: 'none',
}));

const PreviousLevelText = styled(Typography)({
  fontFamily: "Lato",
  color: "#96A591",
  fontWeight: "400",
});

const LocationText = styled(PreviousLevelText)({
  color: "#343C32",
  fontWeight: "700",
  padding: '4px 8px',
});

const Seperator = styled(ChevronRightIcon)({
  color: "#96A591",
})

const BackIcon = styled(ArrowBackIcon)({
  color: "#343C32",
})

type IContentBreadcrumbsProps = {
  breadcrumbData: IBreadcrumb[];
  navigation: any;
}

const ContentBreadcrumbs = ({ breadcrumbData, navigation }: IContentBreadcrumbsProps) => {
  console.log('Breadcrumbs', breadcrumbData);

  const handleNavigate = (item: IBreadcrumb) => {
    if (navigation) {
      switch (item.type) {
        case Type.Catalogue:
          navigation.navigate('Categories', { id: item.id });
          break;
      
        case Type.Category:
          if (!item.has_content) {
            navigation.navigate('Subcategories', { subId: item.id });
            return;
          }
          navigation.navigate('ContentManagement', { contentId: item.content_id });
          break;

        case Type.ParentSubcategory:
          if (!item.has_content) {
            navigation.navigate('SubSubcategories', { subsubId: item.id });
            return;
          }
          navigation.navigate('ContentManagement', { contentId: item.content_id });
          break;

        case Type.Subcategory:
          if (item.has_content) {
            navigation.navigate('ContentManagement', { contentId: item.content_id });
            return;
          }
          break;

        default:
          break;
      }
    }
  }

  return (
    <MainContainer>
      <IconButton data-test-id="back-button" onClick={() => navigation.goBack()}>
        <BackIcon sx={{ fontSize: { xs: 16, md: 24 } }} />
      </IconButton>
      <InlineFlexContainer sx={{ flexWrap: 'wrap' }}>
        <InlineFlexContainer sx={{ gap: '2px' }}>
          <BreadcrumbElement data-test-id="dashboard-button" onClick={() => navigation.navigate("Catalogue")}>
            <PreviousLevelText sx={{ fontSize: { xs: 14, md: 22 } }}>Dashboard</PreviousLevelText>
          </BreadcrumbElement> 
          <Seperator sx={{ fontSize: { xs: 14, md: 24 } }} />
        </InlineFlexContainer>

        {breadcrumbData.length > 0 && breadcrumbData.map((item, index) => (
          <InlineFlexContainer key={index} sx={{ gap: '2px' }}>
            {index < (breadcrumbData.length - 1) ? 
              <BreadcrumbElement data-test-id={index === 0 ? "catalogue-button" : "category-button"} onClick={() => handleNavigate(item)}>
                <PreviousLevelText sx={{ fontSize: { xs: 14, md: 22 } }}>{item.name}</PreviousLevelText>
              </BreadcrumbElement> : 
              <LocationText sx={{ fontSize: { xs: 14, md: 22 } }}>{item.name}</LocationText>}
            {index < (breadcrumbData.length - 1) && <Seperator sx={{ fontSize: { xs: 14, md: 24 } }} />}
          </InlineFlexContainer>
        ))}
      </InlineFlexContainer>
    </MainContainer>
  );
} 

export default ContentBreadcrumbs;
