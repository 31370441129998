import React from "react";

// Customizable Area Start
import {
  Box,
  Button as MaterialButton,
  IconButton,
  Input,
  TextareaAutosize,
  Typography,
  MenuItem,
  Checkbox,
  styled,
} from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import ClearRounded from "@mui/icons-material/ClearRounded";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Button } from '@builder/component-library';


import { Formik } from "formik";
import * as Yup from "yup";

import EmailAccountRegistrationController, {
  Props, configJSON,
} from "./EmailAccountRegistrationController";
import UnauthorizedHeader from "../../../components/src/UnauthorizedHeader";
import UnauthorizedFooter from "../../../components/src/UnauthorizedFooter";
import ModalInformation from "../../../components/src/ModalInformation";
// Customizable Area End

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  stateList: any;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.onRegistrationFormSubmit = this.onRegistrationFormSubmit.bind(this);
    this.onInformationFormSubmit = this.onInformationFormSubmit.bind(this);
    // Customizable Area End
  }
  validationSchemaEmail = Yup.object().shape({
    email: Yup.string().email(this.validationErrors.email).required(this.validationErrors.email),
    password: Yup.string()
      .matches(this.mapOfRegexes.password, "Password should be at least 8 characters long and contain at least 1 special character and 1 uppercase letter and 1 number")
      .required("Password should be at least 8 characters long and contain at least 1 special character and 1 uppercase letter and 1 number"),
  })
  validationSchemaInformation = Yup.object().shape({
    hospiceName: Yup.string().trim().required(this.validationErrors.hospiceName),
    contactNumber: Yup.string()
      .matches(this.mapOfRegexes.contactNumber, this.validationErrors.contactNumber)
      .required(this.validationErrors.contactNumber),
    website: Yup.string()
      .required(this.validationErrors.website).matches(/^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+(\/.*)?$/,this.validationErrors.website),
    license: Yup.string(),
    address: Yup.string().trim().required(this.validationErrors.address),
    city: Yup.string().trim().required(this.validationErrors.city),
    selectedState: Yup.string().required(this.validationErrors.selectedState),
    postalCode: Yup.string().trim().required(this.validationErrors.postalCode),
    hospiceLogo: Yup.mixed().test(
      "type",
      this.validationErrors.hospiceLogo,
      (file) => {
        return file
          ? file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png"
          : true;
      }
    ),
    agreement: Yup.boolean().required(this.validationErrors.agreement).oneOf([true], this.validationErrors.agreement)
  });
  getErrorClassName = (error: string | null | undefined, touched: boolean | undefined) => {
    return error && touched ? "error" : ""
  }
  isErrorField = (error: string | null | undefined, touched: boolean | undefined) => {
    return error && touched
  }
  renderButtonGroup = (enableOpeningInfoModal: boolean) => (
    <Box flexDirection='row' style={styles.buttonRoutingContainer}>
      <Button
        data-test-id={"btnHospice"}
        text='Hospice'
        textStyle={{ fontFamily: "Lato", color:  "#586554", fontWeight: "700" }}
        style={styles.buttonActive}
      />
      <Button
        data-test-id={"btnCaregiver"}
        text='Caregiver'
        onPress={() => {
          if(enableOpeningInfoModal){
            this.handleOpenInformationModal()
          }
        }  
        }
        textStyle={{ fontFamily: "Lato", color:"#96A591" , fontWeight: "700" }}
        style={styles.buttonInactive}
      />
    </Box>
  )
  renderEmailPasswordForm = () => {
    return (
      <Formik
        data-test-id="registration-form"
        initialValues={this.initialRegistrationFormValues}
        onSubmit={(values) => this.onRegistrationFormSubmit(values)}
        validateOnMount={true}
        innerRef={this.formikRef}
        validationSchema={this.validationSchemaEmail}
      >
        {({
          handleChange,
          handleSubmit,
          errors,
          setFieldTouched,
          touched,
          isValid
        }) => (
          <FirstFormStepContainer>
            {this.renderButtonGroup(true)}
            <MainHeader>{this.signUpHeader}</MainHeader>
            <InputGroup>
            <FormRow>
              <InputContainer>
                <InputLabel>
                  {this.labels.email}
                </InputLabel>
                <FormInput
                  onChange={handleChange("email")}
                  onBlur={() => setFieldTouched("email")}
                  className={this.getErrorClassName(errors.email, touched.email)}
                  id="email"
                  data-test-id="email-field"
                  placeholder={this.placeholders.email}
                  disableUnderline
                />
                {this.isErrorField(errors.email, touched.email) && (
                  <ValidationParagraph  data-test-id="email-error-message" className="error">{errors.email}</ValidationParagraph>
                )}
              </InputContainer>
              </FormRow>
              <FormRow>
              <InputContainer>
                <InputLabel>
                  {configJSON.labels.passwordLabel}
                </InputLabel>
                <FormInput
                  onChange={handleChange("password")}
                  onBlur={() => setFieldTouched("password")}
                  className={this.getErrorClassName(errors.password, touched.password)}
                  id="password"
                  data-test-id="password-field"
                  type={this.state.isPasswordVisible ? "text" : "password"}
                  placeholder={configJSON.placeholders.passwordPlaceholder}
                  disableUnderline
                  endAdornment={
                    <PasswordVisibilityIconButton onClick={this.togglePasswordVisibility}>
                      {this.state.isPasswordVisible ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </PasswordVisibilityIconButton>
                  }
                />
                <ValidationParagraph className={this.getErrorClassName(errors.password, touched.password)}>
                  {errors.password}
                </ValidationParagraph>
              </InputContainer>
              </FormRow>
            </InputGroup>
            <Button
                      data-test-id={"button-next"}
                      text={this.buttonsText.firstStep}
                      loading={this.state.loading}
                      textStyle={{fontFamily: "Lato", color: isValid ? "#fff" :  '#7C8E76' }}
                      onPress={() => {
                        if(isValid){
                          handleSubmit()
                        }
                      }}
                      style={{backgroundColor: isValid ? '#7C8E76' : '#D6DCD5'}}
                    />
                      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography style={{fontFamily: "Lato"}}>
                      {configJSON.labelOr}
                    </Typography>
                    <Button
                      data-test-id={"btn-login"}
                      style={{ backgroundColor: "transparent" }}
                      textStyle={{fontSize: 16, fontWeight: '700', color: '#7C8E76', fontFamily: "Lato"}}
                      text={configJSON.btnTxtLogin}
                      onPress={this.goToLogin}
                    />
                  </Box>
          </FirstFormStepContainer>
        )}
      </Formik>
    )
  }
  renderInformationForm = () => {
    return (
      <Formik
        data-test-id="information-form"
        initialValues={this.initialInformationFormValues}
        onSubmit={(values) => this.onInformationFormSubmit(values)}
        validateOnMount={true}
        validationSchema={this.validationSchemaInformation}
      >
        {({
          handleChange,
          handleSubmit,
          errors,
          setFieldTouched,
          values,
          setFieldValue,
          isValid,
          touched
        }) => (
          <SecondFormStepContainer>
            {this.renderButtonGroup(false)}
            <MainHeader style={{marginBottom: 16}}>{this.signUpHeader}</MainHeader>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <FormRow>
                <InputContainer>
                  <InputLabel>
                    {this.labels.hospiceName}
                  </InputLabel>
                  <FormInput
                    onChange={handleChange("hospiceName")}
                    onBlur={() => setFieldTouched("hospiceName")}
                    className={this.getErrorClassName(errors.hospiceName, touched.hospiceName)}
                    id="hospiceName"
                    data-test-id="hospice-name-field"
                    placeholder={this.placeholders.hospiceName}
                    disableUnderline
                  />
                  {this.isErrorField(errors.hospiceName, touched.hospiceName) && (
                    <ValidationParagraph data-test-id="hospice-name-error-message" className="error">
                      {errors.hospiceName}
                    </ValidationParagraph>
                  )}
                </InputContainer>

                <InputContainer>
                  <InputLabel>
                    {this.labels.contactNumber}
                  </InputLabel>
                  <FormInput
                    onChange={
                      handleChange('contactNumber')
                    }
                    onBlur={() => setFieldTouched("contactNumber")}
                    className={this.getErrorClassName(errors.contactNumber, touched.contactNumber)}
                    id="contactNumber"
                    data-test-id="contact-number-field"
                    placeholder={this.placeholders.contactNumber}
                    disableUnderline
                  />
                  {this.isErrorField(errors.contactNumber, touched.contactNumber) && (
                    <ValidationParagraph className="error">
                      {errors.contactNumber}
                    </ValidationParagraph>
                  )}
                </InputContainer>
              </FormRow>

              <FormRow>
                <InputContainer>
                  <InputLabel>
                    {this.labels.website}
                  </InputLabel>
                  <FormInput
                    onChange={handleChange("website")}
                    onBlur={() => setFieldTouched("website")}
                    className={this.getErrorClassName(errors.website, touched.website)}
                    id="website"
                    data-test-id="website-field"
                    placeholder={this.placeholders.website}
                    disableUnderline
                  />
                  {this.isErrorField(errors.website, touched.website) && (
                    <ValidationParagraph className="error">
                      {errors.website}
                    </ValidationParagraph>
                  )}
                </InputContainer>

                <InputContainer>
                  <InputLabel>
                    {this.labels.license}
                  </InputLabel>
                  <FormInput
                    onChange={handleChange("license")}
                    id="license"
                    data-test-id="license-field"
                    placeholder={this.placeholders.license}
                    disableUnderline
                  />
                </InputContainer>
              </FormRow>

              <FormRow>
                <InputContainer>
                  <InputLabel>
                    {this.labels.address}
                  </InputLabel>
                  <FormTextArea
                    style={{ resize: "none" }}
                    minRows={5}
                    onChange={handleChange("address")}
                    onBlur={() => setFieldTouched("address")}
                    className={this.getErrorClassName(errors.address, touched.address)}
                    id="address"
                    data-test-id="address-field"
                    placeholder={this.placeholders.address}
                  />
                  {this.isErrorField(errors.address, touched.address) && (
                    <ValidationParagraph className="error">
                      {errors.address}
                    </ValidationParagraph>
                  )}
                </InputContainer>
              </FormRow>

              <FormRow>
                <InputContainer>
                  <InputLabel>
                    {this.labels.city}
                  </InputLabel>
                  <FormInput
                    onChange={handleChange("city")}
                    onBlur={() => setFieldTouched("city")}
                    className={this.getErrorClassName(errors.city, touched.city)}
                    id="city"
                    data-test-id="city-field"
                    placeholder={this.placeholders.city}
                    disableUnderline
                  />
                  {this.isErrorField(errors.city, touched.city) && (
                    <ValidationParagraph className="error">{errors.city}</ValidationParagraph>
                  )}
                </InputContainer>

                <InputContainer>
                  <InputLabel>
                    {this.labels.selectedState}
                  </InputLabel>
                  <StateSelect
                    onChange={(event: SelectChangeEvent<any>) => {
                      setFieldValue("selectedState", event.target.value)
                    }}
                    onBlur={() => setFieldTouched("selectedState")}
                    className={this.getErrorClassName(errors.selectedState, touched.selectedState)}
                    defaultValue=""
                    id="state"
                    data-test-id="state-field"
                    disableUnderline
                    displayEmpty
                    IconComponent={KeyboardArrowDownRoundedIcon}
                  >
                    <MenuItem disabled value="">
                      <Typography style={{fontFamily: "Lato", color: "#C3CBC0"}}>{this.placeholders.selectedState}</Typography>
                    </MenuItem>
                    {this.listOfStates.map((state: string) => (
                      <MenuItem value={state} key={state}>{state}</MenuItem>
                    ))}
                  </StateSelect>
                  {this.isErrorField(errors.selectedState, touched.selectedState) && (
                    <ValidationParagraph className="error">
                      {errors.selectedState}
                    </ValidationParagraph>
                  )}
                </InputContainer>
              </FormRow>

              <FormRow>
                <InputContainer>
                  <InputLabel>
                    {this.labels.postalCode}
                  </InputLabel>
                  <FormInput
                    onChange={handleChange("postalCode")}
                    onBlur={() => setFieldTouched("postalCode")}
                    data-test-id="postal-code-field"
                    className={this.getErrorClassName(errors.postalCode, touched.postalCode)}
                    id="postalCode"
                    placeholder={this.placeholders.postalCode}
                    disableUnderline
                  />
                  {this.isErrorField(errors.postalCode, touched.postalCode) && (
                    <ValidationParagraph className="error">
                      {errors.postalCode}
                    </ValidationParagraph>
                  )}
                </InputContainer>
              </FormRow>

              <FormRow>
                <InputContainer>
                  <InputLabel>
                    {this.labels.hospiceLogo}
                  </InputLabel>
                  <input
                    onChange={async (e: React.FormEvent<HTMLInputElement>) => {
                      const target = e.target as HTMLElement & {
                        files: FileList;
                      };

                      if (target.files && target.files.length > 0) {
                        const file = target.files[0];

                        await setFieldValue("hospiceLogo", file);
                        setFieldTouched("hospiceLogo");
                      }
                    }}
                    data-test-id="hospice-logo-field"
                    id="hospiceLogo"
                    type="file"
                    hidden
                  />
                  <UploadLogoButton className={this.getErrorClassName(errors.hospiceLogo, touched.hospiceLogo)}>
                    <label
                      htmlFor="hospiceLogo"
                      className="logo-placeholder"
                      style={{ display: "flex", flex: 1, gap: "8px" }}
                    >
                      <img
                        src={this.hospiceLogoExample}
                        style={{ width: "24px", height: "24px" }}
                      />
                      {this.placeholders.hospiceLogo}
                    </label>
                  </UploadLogoButton>
                  {this.isErrorField(errors.hospiceLogo, touched.hospiceLogo) && (
                    <ValidationParagraph className="error">
                      {errors.hospiceLogo}
                    </ValidationParagraph>
                  )}
                  { values.hospiceLogo && <UploadedFileBox>
                    <Typography style={{fontFamily: "Lato", fontSize: 14, fontWeight: "400"}}>{values.hospiceLogo.name}</Typography>
                    <IconButton data-test-id="clear-logo-button" onClick={() => setFieldValue("hospiceLogo", null)}><ClearRounded style={{ color: "#DC2626", width: 24, height: 24 }} /></IconButton>
                  </UploadedFileBox>}
                </InputContainer>
              </FormRow>

              <AgreementContainer>
                <AgreementCheckbox data-test-id="agreement-field" className={this.getErrorClassName(errors.agreement, touched.agreement)} onChange={(e) => {
                  setFieldValue('agreement', e.target.checked)
                }} onBlur={() => setFieldTouched("agreement")} checkedIcon={<CheckRoundedIcon />} />
                <AgreementParagraph className={this.getErrorClassName(errors.agreement, touched.agreement)}>
                  {`${this.agreementText} `}
                  <span style={{cursor: 'pointer'}} onClick={this.goToTermsAndCondition}>{this.termsAndConditionsLabel}</span> 
                  {` ${this.andText} `}
                  <span style={{cursor: 'pointer'}} onClick={this.goToPrivacyPolicy}>{this.privacyPolicyLabel}</span>
                </AgreementParagraph>
              </AgreementContainer>
            </Box>

            <ConfirmButton  onClick={() => {handleSubmit()}}
              className={!isValid ? "" : "active"}
              disabled={!isValid}
              data-test-id="button-submit-info">{this.buttonsText.lastStep}</ConfirmButton>
          </SecondFormStepContainer>
        )}
      </Formik>)
  }
  renderModalInformation = () => {
    return <ModalInformation isOpen={this.state.isOpenModalInformation} handleClose={this.handleCloseInformationModal}/>
  }
  render() {
    return (
      <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
        <UnauthorizedHeader/>
        {this.renderModalInformation()}
      <SignUpPageContainer>
        <RegisterFormContainer py={1.25}>
          {this.state.registrationStepIndex === 0 ? this.renderEmailPasswordForm() : this.renderInformationForm()}
        </RegisterFormContainer>
        <CardMediaBox>
          <Video
            controls
            autoPlay={this.state.windowSize >= 900}
            src={this.state.videoUrl}
          >
            Your browser doesn’t support HTML5 video.
          </Video>
        </CardMediaBox>
      </SignUpPageContainer>
      <UnauthorizedFooter data-test-id="footer" goToPP={this.goToPrivacyPolicy} goToTAC={this.goToTermsAndCondition}/>
      </div>
    );
  }
}

// Customizable Area Start
const SignUpPageContainer = styled(Box)({
  display: "flex",
  flex: 1,
  padding: "10px 0 10px 0",
  flexDirection: 'row'
});

const RegisterFormContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  flex: 1,
});

const CardMediaBox = styled(Box)({
  flex: 1,
  "@media (max-width: 1180px)": { display: "none" },
});
const Video = styled('video')({
  width: "100%",
  height: "100%",
  borderRadius: "8px",
  background: "rgb(0, 0, 0, 0.5)",
  objectFit: 'contain'
})
const FirstFormStepContainer = styled(Box)({
  boxSizing: 'border-box',
  height: "100%",
  width: "360px",
  display: "flex",
  paddingLeft: "16px",
  paddingRight: "16px",
  flexDirection: "column",
  justifyContent: "center",
  gap: "30px",
  "& button.active": {
    backgroundColor: "#7C8E76",
    color: "#FFFFFF",
  },
  "@media (max-width: 600px)": {
    width: "100%"
  },
});

const SecondFormStepContainer = styled(Box)({
  boxSizing: 'border-box',
  height: "100%",
  width: "600px",
  paddingLeft: "16px",
  paddingRight: "16px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: "30px",
  "& button.active": {
    backgroundColor: "#7C8E76",
    color: "#FFFFFF",
  },
  "@media (max-width: 600px)": {
    width: "100%"
  },
});

const FormRow = styled(Box)({
  display: "flex",
  gap: "16px",
  "@media (max-width: 600px)": {
    flexDirection: "column",
  },
});

const InputGroup = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
});

const InputContainer = styled(Box)({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  gap: "4px",
  "& .error": {
    color: "#DC2626",
    borderColor: "#F87171",
  },
  "& ::placeholder": {
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 400,
    color: "#C3CBC0",
    textTransform: "none",
  },
  "& .logo-placeholder": {
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 400,
    color: "#C3CBC0",
    textTransform: "none",
  },
});

const InputLabel = styled(Typography)({
  fontFamily: "Lato",
  fontWeight: 700,
  fontSize: 14,
  letterSpacing: 0,
  color: "#343c32ff",
});

const FormInput = styled(Input)({
  width: "100%",
  height: "44px",
  paddingLeft: "8px",
  paddingRight: "8px",
  borderRadius: "8px",
  borderWidth: "1px",
  borderColor: "#C3CBC0",
  borderStyle: "solid",
  fontFamily: "Lato",
  '& .MuiInputBase-input::placeholder': {
    color: '#94A3B8', // Default placeholder color
  },
  '&.error .MuiInputBase-input::placeholder': {
    color: '#94A3B8', // Placeholder color when error class is present
  },
});

const UploadLogoButton = styled(MaterialButton)({
  width: "100%",
  height: "44px",
  paddingLeft: "8px",
  paddingRight: "8px",
  borderRadius: "8px",
  borderWidth: "1px",
  borderColor: "#C3CBC0",
  borderStyle: "solid",
});

const UploadedFileBox = styled(Box)({
  display: "flex",
  gap: "16px",
  alignItems: "center",
  alignSelf: 'flex-start',
  height: "40px",
  paddingLeft: "8px",
  borderRadius: "8px",
  borderWidth: "1px",
  borderColor: "#C3CBC0",
  borderStyle: "solid",
});

const FormTextArea = styled(TextareaAutosize)({
  width: "100%",
  padding: "8px",
  borderRadius: "8px",
  borderWidth: "1px",
  fontSize: '16px',
  borderColor: "#C3CBC0",
  borderStyle: "solid",
  fontFamily: "Lato"
});

const MainHeader = styled(Typography)({
  fontFamily: "Lato",
  fontWeight: 700,
  fontSize: 24,
  letterSpacing: 0,
  color: "#343c32ff",
  alignSelf: 'center'
});

const ValidationParagraph = styled(Typography)({
  fontFamily: "Lato",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "left",
  width: 0,
  minWidth: "100%",
  color: "#7C8E76"
});

const StateSelect = styled(Select)({
  width: "100%",
  height: "44px",
  borderRadius: 8,
  "& .MuiSelect-root": {
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
    paddingLeft: "8px",
    borderWidth: "1px",
    borderColor: "#C3CBC0",
    borderStyle: "solid",
  },
  "& .MuiSelect-select": {
    color: "#586554", 
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 500
  }
});

const ConfirmButton = styled(MaterialButton)({
  height: 44,
  width: "100%",
  borderRadius: 8,
  backgroundColor: "#D6DCD5",
  color: "#7C8E76",
  textTransform: "capitalize",
  fontFamily: 'Lato',
  fontWeight: '700',
  fontSize: 16
});

const PasswordVisibilityIconButton = styled(IconButton)({
  width: "21.65px",
  height: "18.95px",
  "& svg": {
    fill: "#C3CBC0",
  },
});


const AgreementContainer = styled(Box)({
  display: "flex",
  "& .error": {
    color: "#DC2626",
    borderColor: "#DC2626"
  } 
})

const AgreementCheckbox = styled(Checkbox)({
  color: '#7C8E76',
    '& svg': {
      borderRadius: '6px', 
      fill: "#FFFFFF",
      border: '1px solid #7C8E76',
  },
  '&.Mui-checked': {
    '& svg': {
      backgroundColor: "#7C8E76"
    },
  },
  '&.error': {
    color: '#DC2626', 
    '& svg': {
      border: '1px solid #DC2626',
    },
  },
})

const AgreementParagraph = styled(Typography)({
  fontFamily: "Lato",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
  color: "#586554",
  "& span": {
    textDecoration: "underline"
  },
  '&.error': {
    color: '#F87171',
    '& span': {
      color: '#DC2626',
    },
  },
})
const styles = {
  buttonActive: {
    borderRadius: 2, minWidth: 98, minHeight: 52, paddingVertical: '14px', paddingHorizontal: 0, backgroundColor: '#F2F4F1', borderBottomWidth: 1, borderBottomColor: "rgba(124, 142, 118, 0.50)"
  },
  buttonInactive: {
    borderRadius: 2, minWidth: 98, minHeight: 52, paddingVertical: '14px', paddingHorizontal: 0, backgroundColor: '#FFF', borderWidth: 1, borderColor: "#F2F4F1"
  },
  buttonRoutingContainer: {
    display: 'flex', columnGap: 2, alignSelf: 'center'
  },
}
// Customizable Area End
