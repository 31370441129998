import React, { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import { styled } from "@mui/material";
import parse, { domToReact, Element, HTMLReactParserOptions } from "html-react-parser";

import OverviewSection from "./OverviewSection.web";
import ItemWithImage from "./ItemWithImage.web";
import PlainTextItem from './PlanTextItem.web';
import { OrderedListItem } from "./ItemWithLetter.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";

import { IContentData, ISectionData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const webStyles = {
  ingredientsContainer: {
    flex: 1, 
    gap: { xs: '16px', md: '20px' }, 
    justifyContent: 'center'
  }
}

const DescImg = styled('img')({
  width: '100%', 
  height: '100%',
  borderRadius: '8px',
  objectFit: 'cover',
  objectPosition: 'center'
})

const RecipeForMMIC = ({ data }: { data: IContentData }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const parsingOptions: HTMLReactParserOptions = {
    trim: true,
    replace(domNode) {
        if (!(domNode instanceof Element)) return;
        const replacementByName: { [key: string]: JSX.Element } = {
            "ol": <>{renderOrderedListItem(domNode)}</>,
            "ul": <>{domToReact(domNode.children, parsingOptions)}</>,
            "li": <PlainTextItem>{domToReact(domNode.children)}</PlainTextItem>,
        };
        if (!replacementByName[domNode.name]) return;
        return replacementByName[domNode.name];
    },
  }

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          if (group[1][0]?.definition === "") {
            return {
              name: group[0],
              definition: '',
              images: group[1][0]?.images,
              children: group[1].slice(1).map(each => {
                return { definition: each.definition, images: each.images }
              })
            }
          }
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          id: group[1][0].id,
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  const renderOrderedListItem = (node: Element) => 
    node.children
      .filter(child => child instanceof Element)
      .map((child, index) => {
        if (!(child instanceof Element)) return <></>;
        return (
          <OrderedListItem 
            key={index} 
            order={(index + 1).toString()} 
            isNum
            styles={{ flexWrap: { xs: 'nowrap' } }}
            orderContainerStyles={{ flexBasis: { xs: 0 } }}
          >
            {domToReact(child.children, parsingOptions)}
          </OrderedListItem>);
      })

  return (
    <SectionListLayout>
      <OverviewSection 
          images={title?.images.map(image => image.url) || []} 
          name={parse(title?.title)} 
          shortDesc={title?.desc && title?.desc !== '' && parse(title?.desc)}
      />
      {sectionList.map((section, index) => {
          if (section.children) {
            return (
              <CustomSectionContainer
                key={index} 
                withHeading 
                heading={parse(section.name)}
                headingStyle={{ textAlign: 'center' }}
                containerStyle={{ py: { xs: 2.5, md: 5 } }}
                contentStyle={[
                  section.images.length > 0 && { flexDirection: 'row', flexWrap: 'wrap-reverse' },
                  { alignItems: 'stretch', justifyContent: 'center' }
                ]}
              >
                {section.images.length > 0 
                  ? (
                  <Stack direction="column" sx={webStyles.ingredientsContainer}>
                    {section.children.map((child, index) => 
                      <ItemWithImage 
                        key={index} 
                        text={parse(child.definition)} 
                        image={child.images[0]?.url || ''}
                        style={{ border: 'none', p: '18px 20px' }}
                      />)}
                  </Stack>) 
                : (
                  <>
                    {section.children.map((child, index) => 
                      <ItemWithImage 
                        key={index} 
                        text={parse(child.definition)} 
                        image={child.images[0]?.url || ''} 
                      />)}
                  </>)
                }
                {section.images.length > 0 && <Box sx={{ flex: 1, flexBasis: { xs: '100%', md: 0 }, alignSelf: 'stretch' }}>
                  <DescImg src={section.images[0]?.url || ''} alt="Description Image" />
                </Box>}
              </CustomSectionContainer> 
            );
          }
          return (
            <CustomSectionContainer
              key={index} 
              withHeading 
              heading={parse(section.name)}
              containerStyle={{ py: { xs: 2.5, md: 5 } }}
              // contentStyle={{ flexDirection: section.images.length > 0 ? 'row' : undefined, alignItems: 'stretch', justifyContent: 'center' }}
            >
              {parse(section.definition, parsingOptions)}
            </CustomSectionContainer> 
          );
        }
      )}
    </SectionListLayout>
  );
}
export default RecipeForMMIC;
