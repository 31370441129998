import React, { useEffect, useState } from "react";
import { Grid, Link } from "@mui/material";
import { styled } from "@mui/system";
import parse, { domToReact, Element, HTMLReactParserOptions } from "html-react-parser";

import OverviewSection from "./OverviewSection.web";
import ItemWithImage from "./ItemWithImage.web";
import PlainTextItem from "./PlanTextItem.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";

import { IContentData, ISectionData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const webStyles = {
  alertContainer: { backgroundColor:  '#FEF2F2', border: '2px dashed #F87171' }
}

const CustomLink = styled(Link)({
  color: '#343C32',
  textDecorationColor: '#343C32'
})

const AppetiteFood = ({ data }: { data: IContentData }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const parsingOptions: HTMLReactParserOptions = {
    trim: true,
    replace(domNode) {
      if (!(domNode instanceof Element)) return;
      const replacementByName: { [key: string]: JSX.Element } = {
          "ul": <>{domToReact(domNode.children, parsingOptions)}</>,
          "li": <PlainTextItem>{domToReact(domNode.children, parsingOptions)}</PlainTextItem>,
          "a": <CustomLink href={domNode.attribs['href']} target="_blank">{domToReact(domNode.children)}</CustomLink>,
      };
      if (!replacementByName[domNode.name]) return;
      return replacementByName[domNode.name];
    },
  }

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          id: group[1][0].id,
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);
  
  return (
    <SectionListLayout>
      <OverviewSection 
        images={title ? title?.images.map(image => image.url) : []} 
        name={title && parse(title?.title)} 
        shortDesc={title && title?.desc && title?.desc !== '' && parse(title?.desc)}
      />
      {sectionList                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
        .map((section, index) => {
          if (section.children && section.children.length > 0) {
            return (
              <CustomSectionContainer
                key={index}
                withHeading
                heading={parse(section.name)}
                containerStyle={{ ...webStyles.alertContainer, py: { xs: 2.5, md: 5 } }} 
                headingStyle={{ textAlign: 'center' }}
              >
                <Grid container spacing={2.5}>
                  {section?.children && section?.children.map((child, index) => (
                    <Grid key={index} item xs={12} md={6}>
                      <ItemWithImage 
                        image={child.images[0]?.url || ''} 
                        text={parse(child.definition)}
                        style={{ flexWrap: { xs: 'wrap', md: 'nowrap' } }}
                        textStyle={{ flexBasis: '100%' }} 
                      />
                    </Grid>
                  ))}
                </Grid>
              </CustomSectionContainer>)}
          if (section.id && sectionList[index + 1] && sectionList[index + 1]?.id && sectionList[index + 2] && sectionList[index + 2]?.id) {
              return (
                <CustomSectionContainer key={index} containerStyle={{ py: { xs: 2.5, md: 5 } }}>
                  {[...Array(3).keys()].map((num) => {
                    if (num === 2) {
                      return (
                        <CustomSectionContainer
                          key={num}
                          containerStyle={{ mt: 2.5, padding: { xs: '40px 20px' }, backgroundColor:  '#FEF2F2', border: '2px dashed #F87171' }}
                          contentStyle={{ gap: 1.5 }}
                        >
                          {parse(sectionList[index + num].definition, parsingOptions)}
                        </CustomSectionContainer>
                      )
                    }
                    return (
                      <CustomSectionContainer
                        key={num}
                        withHeading
                        heading={parse(sectionList[index + num].name)}
                        containerStyle={{ padding: { xs: 0 } }}
                        contentStyle={{ gap: 1.5 }}
                      >
                        {parse(sectionList[index + num].definition, parsingOptions)}
                      </CustomSectionContainer>)
                  })}
                </CustomSectionContainer>)} 
        })}
    </SectionListLayout>
  );
}

export default AppetiteFood;
