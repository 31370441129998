import React from 'react'
import { loadStripe } from "@stripe/stripe-js";
import {
    Elements,
    PaymentElement,
} from "@stripe/react-stripe-js";
import CheckoutForm from './CheckoutForm'

type Props = {
    stripeClientSecret: string
    onClose: () => void
    isShowModal: boolean
    plan: any
}
function StripePayment(props: Props) {
    const { stripeClientSecret } = props
    const stripePromise = loadStripe("pk_test_51PBezHRv0fG0yQZXBaZMD4DUeqX58JvXQnPL7wZTHfLaCbxpiIRPuJhyAI8CBbhQADoHdXqOYIZreN5ehQjpZqpM007zyiFCjj");
    const options = {
        clientSecret: stripeClientSecret,
    };
    return (
        <Elements options={options} stripe={stripePromise}>
            <CheckoutForm {...props} />
        </Elements>
    )
}

export default StripePayment