import React from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles'
import { Box, Button } from '@mui/material'; 
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { lighten } from '@mui/system';
const config = require("../../framework/src/config");
import Popover from '@mui/material/Popover';
const phoneIcon = require('./call_icon.svg');
const logoutIcon = require('./logout.svg');
const honeIcon = require('./hone_icon.svg');
const thumbUpIcon = require('./thumb_up_icon.svg');
import HospiceHeader from "./HospiceHeader"
import CustomModal from "./CustomModal"

interface AuthorizedHeaderProps {
    navigation: any
    isHideHome?: boolean
}

export default function AuthorizedHeader(props : AuthorizedHeaderProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [isShowModal, setIsShowModal] = React.useState(false)
  const currentUserData = localStorage.getItem("caregiver_data") ?? ""
  const currentHospiceData = localStorage.getItem("user_data") ?? ""
  const currentRole = localStorage.getItem("current_role") ?? ""
  const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
  
      const { 
        navigation,
        isHideHome
       } = props;
  const renderCaregiverHeader = () => {
    const currentData = JSON.parse(currentUserData)
    const patientData = currentData?.attributes ?? ""
    const hospiceData = patientData?.hospice?.attributes ?? ""
  
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const onLogout = () => {
      localStorage.removeItem("token")
      localStorage.removeItem("caregiver_data")
      localStorage.removeItem("current_role")
      localStorage.removeItem("user_status")
      navigation.navigate("EmailAccountLoginBlock")
    }
    const handleClose = () => {
      setAnchorEl(null);
    };
  
       const getHospiceName = () => {
          return hospiceData?.hospice_name ?? "No Name"
      }
      const getHospiceLogo = () => {
        if(hospiceData && hospiceData !== ""){
          return hospiceData.hospice_logo ? `${config.baseURL}/${hospiceData.hospice_logo}` : require('./hospice_logo.png')
        }
        return require('./hospice_logo.png')
      }
      return (
        <>
         <CustomModal
            open={isShowModal}
            headerTitle="Logout"
            handleCloseModal={() => setIsShowModal(false)}
            renderContent={() => <LatoText >Are you sure you want to logout?</LatoText>}
            textButtonRight="Yes"
            handleClickButtonRight={onLogout}
            textButtonLeft="No"
            loading={false}
        />
      <HeaderContainer>
        <ImageContainer>
          <img style={webStyles.image} src={require('./group_logo.png')} />
        </ImageContainer>
        <ImageMobile>
          <img style={webStyles.imageMobile} src={require('./logo_mobile.png')} />
        </ImageMobile>
        <HospiceInfoContainer>
          <HospiceLogo src={getHospiceLogo()} />
          <HeaderText fontSize={28}>{getHospiceName()}</HeaderText>
        </HospiceInfoContainer>
        <UserPanel>
          <HeaderText fontSize={18}>
          {patientData?.patient_name ?? "patient name"}
          </HeaderText>
          <Button style={{background: 'transparent', boxShadow: 'none'}} aria-describedby={id} variant="contained" onClick={handleClick} >
            <HamburgerIcon />
          </Button>
  
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
              <DropdownMenuContainer data-test-id={"user-panel"}>
                <CaregiverInfoContainer>
                  <Box 
                  borderBottom={"1px solid rgba(124, 142, 118, 0.2)"}
                  paddingBottom={"4px"}
                  >
                    <DropdownMenuText
                      fontSize={16}
                      color={"#343C32"}
                    >
                      {patientData?.caregiver_name ?? "patient name"}
                    </DropdownMenuText>
                    <DropdownMenuText
                      fontSize={12}
                      color={"#A7B3A3"}
                      fontStyle={"italic"}
                    >
                      taking care of {patientData?.patient_name ?? "patient name"}
                    </DropdownMenuText>
                  </Box>
                  <DropdownMenuText
                    fontSize={16}
                    color={"#343C32"}
                    marginTop={"6px"}
                  >
                    Used {patientData?.no_of_devices ?? "1"} of 3 devices allowed
                  </DropdownMenuText>
                </CaregiverInfoContainer>
                <ControlsContainer>
                  {!isHideHome &&<ControlRow onClick={() => {navigation.navigate("Catalogue")}}>
                    <img src={honeIcon}/>
                    <ControlsText>
                      Home
                    </ControlsText>
                  </ControlRow>}
                  <ControlRow onClick={() => {navigation.navigate("ContactInfo")}}>
                    <img src={phoneIcon}/>
                    <ControlsText>
                      Hospice Contact Info
                    </ControlsText>
                  </ControlRow>
                  <ControlRow onClick={() => {navigation.navigate("Contactus")}}>
                    <img src={thumbUpIcon}/>
                    <ControlsText>
                      Give Website Feedback
                    </ControlsText>
                  </ControlRow>
                  <ControlRow onClick={() => {
                    handleClose()
                    setIsShowModal(true)
                  }}>
                    <img src={logoutIcon}/>
                    <ControlsText>
                      Logout
                    </ControlsText>
                  </ControlRow>
                </ControlsContainer>
              </DropdownMenuContainer>
              </Popover>
        </UserPanel>
      </HeaderContainer>
      </>
    );
  }
  if(currentRole === "hospice"){
    return <HospiceHeader hospiceView navigation={navigation}/>
  }
  return renderCaregiverHeader()
 
}

const HeaderContainer = styled(Box)({
  position: 'relative',
    background: '#7C8E76',
    width: '100%',
    alignSelf: 'start',
    padding: '0px 48px 0px 48px',
    display: 'flex',
    height: 96,
    flexDirection: 'row',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '11.34px',
    '@media (max-width: 899px)': {
      padding: '0 16px',
      borderRadius: 0
    },
    zIndex: 10
});

const HeaderText = styled(Typography)({
  color: '#FFFFFF',
  fontWeight: 700,
  paddingLeft: 16,
  fontFamily: "Lato",
  '@media (max-width: 899px)': {
    display: 'none',
  }
});

const HospiceInfoContainer = styled(Box)({
    display: "flex",
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: "center",
    justifyContent: 'center',
    width: "100%",
    height: "100%",
    '@media (max-width: 899px)': {
      display: 'none',
    }
});

const HospiceLogo = styled('img')({
    width: "42px",
    height: "42px",
    borderRadius: 42
});

const UserPanel = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "8px",
    position: "relative",
    padding: "20px 0px 20px 0px"
});

const HamburgerIcon = styled(MenuRoundedIcon)({
    color: "#FFFFFF",
    "&:hover": {
      cursor: "pointer",
      color: lighten('#FFFFFF', 0.3)
    }
})
const ImageMobile = styled("div")({
  width: 265,
  height: 58,
  display: 'none',
  '@media (max-width: 899px)': {
    display: 'flex'
  }
});

const ImageContainer = styled("div")({
  width: 160,
  height: 68,
  display: 'flex',
  '@media (max-width: 899px)': {
    display: 'none'
  }
});
const DropdownMenuContainer = styled(Box)({
  width: "300px",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  backgroundColor: "#FFFFFF",
  padding: '20px 12px 20px 12px',
  borderRadius: "8px",
  overflow: 'hidden',
  boxShadow: '3px 3px 32px rgba(52, 60, 50, 0.16)',
})

const DropdownMenuText = styled(Typography)({
  fontFamily: "Lato",
  fontWeight: 700, 
  padding: "0px 20px 0 20px"
})
const LatoText = styled(Typography)({
  fontSize: 16,
  fontFamily: "Lato",
  color: "#343C32",
})
const CaregiverInfoContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "4px",
  height: "113px",
  padding: "20px 0px 20px 0px",
  boxSizing: "border-box",
  backgroundColor: "#F2F4F1",
  borderRadius: "8px",
  textOverflow: 'ellipsis',
})

const ControlsText = styled(Typography)({
  fontFamily: "Lato",
  fontWeight: 500,
  fontSize: "16px",
  color: "#586554",
  "&:hover": {
    textDecoration: "underline"
  }
})

const ControlsContainer = styled(Box)({
  display: "flex",
  flexDirection: "column"
})

const ControlRow = styled(Box)({
  flex: 1,
  display: "flex",
  gap: "4px",
  padding: "12px 16px 12px 16px",
  borderBottom: '1px solid rgba(124, 142, 118, 0.2)',
  whiteSpace: 'nowrap', 
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  cursor: 'pointer'
})

const webStyles = {
  image: {
    width: 160,
    height: 68,
  },
  imageMobile: {
    width: 265,
    height: 58,
  },
  mobileModalContainer: {
    '@media (max-width: 899px)': {
      top: 'auto',
      bottom: '0',
      left: '0',
      width: '100%',
      borderRadius: 0,
      borderTopRightRadius: 32
    }
  }
}
