import React, { useEffect, useState } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { styled } from '@mui/system';
import parse, { attributesToProps, domToReact, Element, HTMLReactParserOptions } from "html-react-parser";

import OverviewSection from "./OverviewSection.web";
import PlainTextItem from "./PlanTextItem.web";
import ItemWithImage from "./ItemWithImage.web";
import { OrderedListItem } from "./ItemWithLetter.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";

import { IContentData, ISectionData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const webStyles = {
  sectionLayout: {
    borderRadius: '8px', 
    gap: '10px', 
    backgroundColor: '#F2F4F1', 
    paddingBottom: '20px'
  }
}
const PlainListWithHeadingBox = styled(Stack)({
  height: 'calc(100% - 40px)', 
  width: '100%',
  backgroundColor: '#FFF', 
  padding: '20px',
  gap: '20px',
  '& p, h5': {
    lineHeight: '32px',
    color: '#586554', 
    fontFamily: 'Lato',
    margin: 0
  },
  '& div > svg': { marginBottom: '-8px' }
});
const SubHeadingContainer = styled(Typography)({
  fontFamily: 'Lato',
  color: '#586554',
});

const Sleep = ({ data }: { data: IContentData }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const parsingOptions: HTMLReactParserOptions = {
    trim: true,
    replace(domNode) {
      if (!(domNode instanceof Element)) return;
      const props = attributesToProps(domNode.attribs);
      const replacementByName: { [key: string]: JSX.Element } = {
        "ul": <>{domToReact(domNode.children, parsingOptions)}</>,
        "li": domNode.children.some(child => child instanceof Element && child.name === 'div') 
          ? renderOrderedListItem(domNode)
          : <PlainTextItem style={{ mb: 1 }}>{domToReact(domNode.children, parsingOptions)}</PlainTextItem>,
        "small": (
          <SubHeadingContainer 
            {...props} 
            sx={{ fontSize: { xs: '16px', md: '20px' }, lineHeight: { xs: '19.2px', md: '32px' } }}
          >
            {domToReact(domNode.children)}
          </SubHeadingContainer>
        )
      };
      if (!replacementByName[domNode.name]) return;
      return replacementByName[domNode.name];
    },
  }

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  const renderOrderedListItem = (node: Element) => {
    const orderNode = node.children.find(child => child instanceof Element && child.name === 'div');
    if (orderNode) {
      const order = orderNode instanceof Element && domToReact(orderNode.children);
      return (
        <OrderedListItem order={order || ''} styles={{ mt: 2.5 }}>
          {domToReact(node.children.filter(child => child instanceof Element && child.name !== 'div'))}
        </OrderedListItem>
      )
    }
    return <></>
  }

  return (
    <SectionListLayout styles={webStyles.sectionLayout}>
      <OverviewSection 
        images={title?.images.map(image => image.url) || []} 
        name={parse(title?.title)} 
        shortDesc={title?.desc && title?.desc !== '' && parse(title?.desc)}
      />
      {sectionList.map((section, index) => {
        if (section.children) {
          return (
            <CustomSectionContainer
              key={index}
              withHeading
              heading={parse(section.name)}
              containerStyle={{ py: { xs: 2.5, md: 5 } }}
            >
              <Grid container spacing={2.5}> 
                {section?.children.map((child, index) => (
                  <Grid key={index} item xs={12} md={6} sx={{ display: 'flex' }}>
                    {child.images.length > 0 
                      ? <ItemWithImage image={child.images[0].url} text={parse(child.definition)} /> 
                      : (
                        <PlainListWithHeadingBox sx={{ '& h5': { fontSize: { xs: '20px', md: '26px' } } }}>
                          {parse(child.definition, parsingOptions)}
                        </PlainListWithHeadingBox>
                      )}
                  </Grid>
                ))}                                                                                                          
              </Grid>
            </CustomSectionContainer>
          );
        }
        return (
          <CustomSectionContainer
            key={index}
            withHeading
            heading={parse(section.name, parsingOptions)}
            containerStyle={{ py: 2.5 }}
            contentStyle={{ gap: { xs: 1.5, md: 2.5 } }}
          >
            {parse(section.definition, parsingOptions)}
          </CustomSectionContainer>
        )
      })}
    </SectionListLayout>
  );
}

export default Sleep;
