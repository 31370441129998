// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import Cfpingenerator from "../../blocks/cfpingenerator/src/Cfpingenerator";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Videos from "../../blocks/videos/src/Videos";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Cfrichtexteditorinadminconsole from "../../blocks/cfrichtexteditorinadminconsole/src/Cfrichtexteditorinadminconsole";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Settings2 from "../../blocks/settings2/src/Settings2";
import ViewBilling from "../../blocks/settings2/src/ViewBilling";
import DeleteAccount from "../../blocks/settings2/src/DeleteAccount.web";
import Cfuniquelinklandingpageforeachuser from "../../blocks/cfuniquelinklandingpageforeachuser/src/Cfuniquelinklandingpageforeachuser";
import Contactus from "../../blocks/contactus/src/Contactus2.web";
import ContactInfo from "../../blocks/contactus/src/ContactInfo.web";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2";
import SubscriptionExpired from "../../blocks/subscriptionbilling2/src/SubscriptionExpired";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Analytics from "../../blocks/analytics/src/Analytics";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import AboutUs from "../../blocks/landingpage/src/AboutUs.web";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import ContentManagement from "../../blocks/contentmanagement/src/ContentManagement"
import ContentManagementGlossaries from "../../blocks/contentmanagement/src/ContentManagementGlossaries.web"
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";
import { GlobalStyles } from '@mui/material';


const routeMap = {
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
Cfpingenerator:{
 component:Cfpingenerator,
path:"/Cfpingenerator",
role: "hospice"},
CfpingeneratorEdit:{
  component:Cfpingenerator,
 path:"/CfpingeneratorEdit/:patientId",
 role: "hospice"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
TermsConditions:{
 component:TermsConditions,
path:"*/TermsConditions", modal: true},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Videos:{
 component:Videos,
path:"/Videos"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue",
role: "caregiver|hospice"},
Cfrichtexteditorinadminconsole:{
 component:Cfrichtexteditorinadminconsole,
path:"/Cfrichtexteditorinadminconsole"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Settings2:{
 component:Settings2,
path:"/Settings2",
role: "hospice"},
SubscriptionExpired:{
 component:SubscriptionExpired,
path:"/SubscriptionExpired"},
ViewBilling:{
 component:ViewBilling,
path:"/ViewBilling",
role: "hospice"},
DeleteAccount:{
 component:DeleteAccount,
path:"/DeleteAccount",
role: "hospice"},
ContentManagement:{
  component:ContentManagement,
 path:"/ContentManagement/:contentId",
 role: "caregiver|hospice"},
ContentManagementGlossaries:{
  component:ContentManagementGlossaries,
 path:"/glossaries",
 role: "caregiver|hospice"},
Cfuniquelinklandingpageforeachuser:{
 component:Cfuniquelinklandingpageforeachuser,
path:"/Cfuniquelinklandingpageforeachuser"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
ContactInfo:{
  component:ContactInfo,
 path:"/ContactInfo", 
 role: "caregiver"
},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Subscriptionbilling2:{
 component:Subscriptionbilling2,
path:"/Subscriptionbilling2"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Categories:{
 component:Categoriessubcategories,
path:"/Categories/:id",
role: "caregiver|hospice"},
Subcategories:{
 component:Categoriessubcategories,
path:"/Subcategories/:subId",
role: "caregiver|hospice"},
SubSubcategories:{
 component:Categoriessubcategories,
path:"/SubSubcategories/:subsubId",
role: "caregiver|hospice"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Dashboard:{
  component:Dashboard,
 path:"/dashboard",
 role: "hospice"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
AboutUs:{
  component:AboutUs,
 path:"/AboutUs"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },

  Alert: {
    component: AlertBlock,
    path: "*/Alert",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
  
    gtag('config', 'G-DR7SB9X31R');
  
    const userType = localStorage.getItem('current_role') ?? "guest";
    gtag('set', 'user_properties', {
      'user_type': userType
    });

    const userData = localStorage.getItem('user_data'); 
    const parsedData = JSON.parse(userData) || {};
    gtag('set', 'user_id', parsedData?.data?.id ?? '' );
   
    return (
      <BuilderProvider>
        <GlobalStyles styles={{"#root > div, #root > div > div, #root > div > div>div>div, #root > div > div>div>div>div": {minWidth: 0}}}/>
        <View style={{ height: '100vh', width: '100%', minWidth: 0 }}>
          <TopNav />
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
        </View>
      </BuilderProvider>
    );
  }
}

export default App;