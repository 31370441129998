import React, { FC, ReactNode } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Typography } from '@mui/material';
import { styled, SxProps } from '@mui/system';
import { lineHeight } from '@mui/system';

const Container = styled(Box)({
  display: 'flex', 
  alignItems: 'start', 
  gap: '10px'
})

const NormalText = styled(Typography)({
  fontWeight: '400',
  fontFamily: 'Lato',
});

type IPlainTextItemProps = {
  children: ReactNode;
  withoutIcon?: boolean;
  icon?: JSX.Element | string;
  style?: SxProps; 
  textStyle?: SxProps;
}

const PlainTextItem: FC<IPlainTextItemProps> = (props) => (
  <Container sx={[ ...(Array.isArray(props.style) ? props.style : [props.style]) ]}>
    {!props.withoutIcon && (!props.icon ? <ChevronRightIcon /> : props.icon)}
    <Box sx={{ flex: 1, display: { xs: 'block', md: 'none' } }}>
      <NormalText sx={{ fontSize: '16px', lineHeight: '19.2px', ...props.textStyle }}>{props.children}</NormalText>              
    </Box>
    <Box sx={{ flex: 1, display: { xs: 'none', md: 'block' } }}>
      <NormalText sx={{ fontSize: '22px', lineHeight: '26.4px', ...props.textStyle }}>{props.children}</NormalText>              
    </Box>
  </Container>
);

export default PlainTextItem;