import React, { FC } from "react";
// Customizable Area Start
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import parse, { Element, domToReact } from 'html-react-parser';

import { illustration1Img, ownerAvatarImg } from "./assets";
import AboutUsController, { Props, WithChildrenProps } from "./AboutUsController.web";
import { UnauthPageWrapper } from "./LandingPage.web";
// Customizable Area End

export default class AboutUs extends AboutUsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start
  renderSectionHeading: FC<WithChildrenProps> = ({ children }) => (
    <SectionHeading sx={{ 
      fontSize: { xs: "20px", md: "24px" }, 
      lineHeight: { xs: "24px", md: "32px" }, 
      '& span': { fontSize: { xs: "20px", md: "36px"} } 
    }}>
      {children}
    </SectionHeading>
  );
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <UnauthPageWrapper
        currentItem={this.props.navigation.getLocation()} 
        navigationData={this.state.sections[this.state.sections.length - 2]} 
        newsletterData={this.state.sections[this.state.sections.length - 1]} 
        onNavigate={screen => this.naviagteTo(screen)}
      >
        {this.state.sections.length > 0 && 
          <CenterFlexCol sx={{ gap: "33px", mt: { xs: "100px", md: "156px" }, mb: { xs: 2.5, md: 7 } }}>
            {this.state.sections[0] &&
              <>
                <CenterFlexCol>
                  <Box sx={{ padding: "10px" }}>
                    {this.renderSectionHeading({ children: parse(this.state.sections[0].term) })}
                  </Box>
                  <IllustrationImg src={illustration1Img} alt="Illustration 1" sx={{ height: { xs: '178px', md: '432px' } }}/>
                </CenterFlexCol>
                <CenterFlexCol
                  sx={{
                    px: { xs: 2, md: "130px" },
                    py: "10px",
                    gap: "10px",
                    color: "#586554",
                    textAlign: "center",
                  }}
                >
                  {parse(this.state.sections[0].definition, {
                    replace(domNode) {
                      if (domNode instanceof Element && domNode.name === 'p') {
                        return <MdText sx={{ fontSize: { xs: "16px", md: "20px" } }}>
                          {domToReact(domNode.children)}
                        </MdText>
                      }
                    },
                  })}
                </CenterFlexCol>
              </>}
            {this.state.sections[1] && 
              <CenterFlexCol sx={{ paddingX: { xs: "16px", md: "130px" }, gap: 2.5 }}>
                <Box sx={{ padding: "10px" }}>
                  {this.renderSectionHeading({ children: parse(this.state.sections[1].term) })}
                </Box>
                <Box sx={{ 
                  display: { xs: 'flex', md: 'block' }, 
                  flexDirection: 'column', 
                  alignItems: 'center', 
                  color: "#586554", 
                }}>
                  <OwnerAvatar
                    src={ownerAvatarImg}
                    alt="Owner Avatar"
                    sx={{ float: { xs: "none", md: "right" }, ml: { xs: 0, md: "13px" }, mb: { xs: "12px", md: 0 } }}
                  />
                  <Box sx={{ color: "#586554", "& div:not(:last-child)": { marginBottom: "8px" } }}>
                    {parse(this.state.sections[1].definition, {
                      replace(domNode) {
                        if (domNode instanceof Element && domNode.name === 'p') {
                          return <MdText sx={{ fontSize: { xs: "16px", md: "20px" } }}>
                            {domToReact(domNode.children)}
                          </MdText>
                        }
                      },
                    })}
                  </Box>
                </Box>
              </CenterFlexCol>}
            {this.state.sections[2] && this.state.sections[2].children &&
              <CenterFlexCol sx={{ paddingX: { xs: 2, md: "130px" }, gap: 2.5 }}>
                <Box sx={{ padding: "10px" }}>
                  {this.renderSectionHeading({ children: parse(this.state.sections[2].term) })}
                </Box>
                <FlexCol sx={{ gap: 5, color: "#586554" }}>
                  {this.state.sections[2]?.children?.map((purpose, index) => (
                    <FlexCol key={index} sx={{ gap: "13px" }}>
                      {parse(purpose.definition, {
                        replace(domNode) {
                          if (domNode instanceof Element && domNode.name === 'h5') {
                            return <BaseText sx={{ fontSize: { xs: "20px", md: "24px" }, fontWeight: "700" }}>
                              {domToReact(domNode.children)}
                            </BaseText>
                          }
                          if (domNode instanceof Element && domNode.name === 'p') {
                            return <MdText sx={{ fontSize: { xs: "16px", md: "20px" } }}>
                              {domToReact(domNode.children)}
                            </MdText>
                          }
                        },
                      })}
                    </FlexCol>
                  ))}
                </FlexCol>
              </CenterFlexCol>}
          </CenterFlexCol>
        }
      </UnauthPageWrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const FlexCol = styled(Box)({
  display: "flex",
  flexDirection: "column",
});
const CenterFlexCol = styled(FlexCol)({
  alignItems: "center",
});
const BaseText = styled(Box)({
  fontFamily: "Lato",
  "& p": {
    margin: 0
  }
});
const MdText = styled(BaseText)({
  fontSize: "20px",
  fontWeight: "400",
});
const SectionHeading = styled(BaseText)({
  fontWeight: "700",
  lineHeight: "32px",
  fontSize: "24px",
  color: "#343C32",
  textAlign: "center",
  "& span": {
    fontSize: "36px",
    color: "#94CE81",
  },
});
const IllustrationImg = styled('img')({
  width: 'auto', 
  objectFit: 'contain',
});
const OwnerAvatar = styled('img')({
  marginLeft: "13px"
});
// Customizable Area End
