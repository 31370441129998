import React, { useEffect, useState } from "react";
import { Link } from "@mui/material";
import { styled } from "@mui/system";
import parse, { domToReact, Element, HTMLReactParserOptions } from "html-react-parser";

import OverviewSection from "./OverviewSection.web";
import PlainTextItem from "./PlanTextItem.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";

import { IContentData, ISectionData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const CustomLink = styled(Link)({
  color: '#343C32',
  textDecorationColor: '#343C32'
})

const EatingDrinking = ({ data }: { data: IContentData }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const parsingOptions: HTMLReactParserOptions = {
    replace(domNode) {
      if (!(domNode instanceof Element)) return;
      const replacementByName: { [key: string]: JSX.Element } = {
        "ul": <>{domToReact(domNode.children, parsingOptions)}</>,
        "li": <PlainTextItem>{domToReact(domNode.children, parsingOptions)}</PlainTextItem>,
        "a": (
          <CustomLink 
            href={domNode.attribs['href']} 
            target="_blank"
          >
            {domToReact(domNode.children)}
          </CustomLink>
        ),        
      };

      if (!replacementByName[domNode.name]) return;
  
      return replacementByName[domNode.name];
    },
  }

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          id: group[1][0].id,
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);
  
  return (
    <SectionListLayout>
      <OverviewSection 
        images={title ? title?.images.map(image => image.url) : []} 
        name={title && parse(title?.title)} 
        shortDesc={title && title?.desc && title?.desc !== '' && parse(title?.desc)}
        containerStyle={{ p: { xs: '20px 16px', md: '40px 189px' } }}
      />
      {sectionList                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
        .map((section, index) => (
          <CustomSectionContainer
            key={index}
            withHeading
            heading={parse(section.name)}
            containerStyle={{ px: { xs: 2, md: '130px' }, py: 2.5 }}
            contentStyle={{ width: 'fit-content', mt: 0, p: 2.5, gap: 1.5  }}
          >
            {parse(section.definition, parsingOptions)}
          </CustomSectionContainer>
        ))}
    </SectionListLayout>
  );
}

export default EatingDrinking;
