import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import parse, { domToReact, Element, HTMLReactParserOptions } from 'html-react-parser';

import OverviewSection from "./OverviewSection.web";
import PlainTextItem from "./PlanTextItem.web";
import { OverviewAnnotation } from "./MethodOverviewSection.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";

import { IContentData, ISectionData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const Oxygen = ({ data }: { data: IContentData }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const options: HTMLReactParserOptions = {
    trim: true,
    replace(domNode) {
      if (!(domNode instanceof Element)) return;
      const replacementByName: { [key: string]: JSX.Element } = {
        "ul": (
          <Stack direction="column" sx={{ gap: '12px' }}>
            {domToReact(domNode.children, options)}
          </Stack>
        ),
        "li": <PlainTextItem>{domToReact(domNode.children, options)}</PlainTextItem>,
      };

      if (!replacementByName[domNode.name]) return;
  
      return replacementByName[domNode.name];
    }
  }

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);
  
  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          id: group[1][0].id,
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  return (
    <SectionListLayout>
      <OverviewSection 
        images={title?.images.map(image => image.url)} 
        name={parse(title?.title)} 
        shortDesc={title?.desc && title?.desc !== '' && parse(title?.desc)}
        containerStyle={{ backgroundColor: 'transparent', padding: { xs: '20px 16px', md: '40px 260px' } }} 
      />
      <CustomSectionContainer variant="light" containerStyle={{ py: 0 }}>
        {sectionList.map((section, index) => {
          return (
            <CustomSectionContainer key={index} containerStyle={{ p: { xs: 2.5 } }}>
              <OverviewAnnotation 
                title={parse(section.name)} 
                content={parse(section.definition, options)}
                image={section.images[0]?.url || ''} 
                titleStyle={{ mb: { xs: 2.5 } }}
                layoutStyle={{ alignItems: 'center' }}
              />
            </CustomSectionContainer>
          )
        })}
      </CustomSectionContainer>
    </SectionListLayout>
  );
}

export default Oxygen;
