import React, { useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { styled } from '@mui/system';
import parse, { domToReact, Element, HTMLReactParserOptions } from 'html-react-parser';

import OverviewSection from "./OverviewSection.web";
import PlainTextItem from "./PlanTextItem.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";

import { IContentData, IImage, ISectionData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const NormalText = styled(Typography)({
  fontWeight: '400',
  fontFamily: 'Lato',
  color: '#343C32',
  fontSize: '16px',
  lineHeight: '19.2px',
  '@media (min-width: 900px)': {
    fontSize: '22px',
    lineHeight: '26.4px',
  } 
});
const HeadingText = styled(NormalText)({
  fontWeight: '700',
  color: '#586554',
  fontSize: '20px',
  lineHeight: '32px',
  '@media (min-width: 900px)': { fontSize: '26px' } 
});
const Container = styled(Box)({
  borderRadius: '8px',
  border: '2px dashed #7C8E76',
  backgroundColor: '#F2F4F1',
  padding: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '@media (min-width: 900px)': {
    justifyContent: 'space-between',
  } 
});
const DescImg = styled('img')({
  width: '120px',
  height: '120px',
  objectFit: 'contain'
});
const HeadingTextContainer = styled(Box)({
  fontWeight: '700',
  fontFamily: 'Lato',
  color: '#343C32',
  fontSize: '20px',
  lineHeight: '32px',
  textAlign: 'center',
  '& p': { 
    margin: 0,
    ':not(:last-child)': { marginBottom: '20px' }, 
    '& small': { 
      fontWeight: '400', 
      fontSize: '16px',
      color: '#7C8E76',
      '@media (min-width: 900px)': { fontSize: '26px' }  
    }
  },
  '@media (min-width: 900px)': { fontSize: '28px' } 
});


const HeadingContainer = ({ heading, images }: { heading: string; images: IImage[]}) => (
  <Container>
    {images.length > 1 && <DescImg src={images[0]?.url || ''} alt="Left Image" sx={{ display: { xs: 'none', md: 'block' } }}/>}
    <HeadingTextContainer>{parse(heading)}</HeadingTextContainer>
    {images.length > 1 && <DescImg src={images[1]?.url || ''} alt="Right Image" sx={{ display: { xs: 'none', md: 'block' } }}/>}
  </Container>
)

const ChangingPositionInBedImportance = ({ data }: { data: IContentData }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const options: HTMLReactParserOptions = {
    trim: true,
    replace(domNode) {
      if (!(domNode instanceof Element)) return;
      const replacementByName: { [key: string]: JSX.Element } = {
        "ul": (
          <Stack direction="column" sx={{ gap: '12px' }}>
            {domToReact(domNode.children, options)}
          </Stack>
        ),
        "li": <PlainTextItem>{domToReact(domNode.children, options)}</PlainTextItem>,
        "h5": <HeadingText sx={{ mb: 1 }}>{domToReact(domNode.children, options)}</HeadingText>,
        "p": <NormalText>{domToReact(domNode.children, options)}</NormalText>
      };

      if (!replacementByName[domNode.name]) return;
  
      return replacementByName[domNode.name];
    }
  }

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          id: group[1][0].id,
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  return (
    <SectionListLayout>
      <OverviewSection 
        images={title?.images.map(image => image.url)} 
        name={parse(title?.title)} 
        shortDesc={title?.desc && title?.desc !== '' && parse(title?.desc)}
        containerStyle={{ backgroundColor: 'transparent' }} 
      />
      {sectionList.map((section, index) => {
        if (section.images.length > 1) {
          return (
            <CustomSectionContainer 
              key={index}
              variant="light"
              containerStyle={{ p: { xs: '20px 16px', md: '40px 130px' } }} 
              contentStyle={{ gap: 5 }}
            >
              <HeadingContainer heading={section.name} images={section.images} />
              <Stack direction="column" sx={{ px: { xs: 0, md: 2.5 }, gap: 1.5 }}>
                {parse(section.definition, options)}
              </Stack>
            </CustomSectionContainer>
          )
        }
        return (
          <CustomSectionContainer 
            key={index}
            withHeading={section.name !== ''}
            heading={parse(section.name)}
            containerStyle={{ p: { xs: '20px 16px', md: '40px 150px' } }} 
            contentStyle={{ gap: 1.5 }}
          >
            {parse(section.definition, options)}
          </CustomSectionContainer>
        )
      })}
    </SectionListLayout>
  );
}

export default ChangingPositionInBedImportance;
