import React, { Children, useEffect, useState } from "react";
import { Box, Grid, Link } from "@mui/material";
import { styled } from "@mui/system";
import parse, { domToReact, Element, HTMLReactParserOptions } from "html-react-parser";

import OverviewSection from "./OverviewSection.web";
import ItemWithImage from "./ItemWithImage.web";
import { OrderedListItem } from "./ItemWithLetter.web";
import PlainTextItem from "./PlanTextItem.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";

import { IContentData, ISectionData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const HeadingText = styled(Box)({
  margin: '0 0 10px',
  fontWeight: '700',
  fontFamily: 'Lato',
  color: '#586554',
});

const MouthCare = ({ data }: { data: IContentData }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const parsingOptions: HTMLReactParserOptions = {
    trim: true,
    replace(domNode) {
      if (!(domNode instanceof Element)) return;
      const replacementByName: { [key: string]: JSX.Element } = {
          "ul": <>{domToReact(domNode.children, parsingOptions)}</>,
          "li": domNode.children.some(child => child instanceof Element && child.name === 'div') 
            ? renderOrderedListItem(domNode)
            : <PlainTextItem style={{ mb: 1 }}>{domToReact(domNode.children, parsingOptions)}</PlainTextItem>,
          "a": (
            <Link 
              href={domNode.attribs['href']} 
              target="_blank"
              sx={{ color: '#343C32', textDecorationColor: '#343C32' }}
            >
              {domToReact(domNode.children)}
            </Link>
          ),          
          "h5": <HeadingText sx={{ fontSize: { xs: '20px', md: '26px' } }}>{domToReact(domNode.children)}</HeadingText>,
      };
      if (!replacementByName[domNode.name]) return;
      return replacementByName[domNode.name];
    },
  }

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          id: group[1][0].id,
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  const renderOrderedListItem = (node: Element) => {
    const orderNode = node.children.find(child => child instanceof Element && child.name === 'div');
    if (orderNode) {
      const order = orderNode instanceof Element && domToReact(orderNode.children);
      return (
        <OrderedListItem order={order || ''} styles={{ mt: 2.5 }}>
          {domToReact(node.children.filter(child => child instanceof Element && child.name !== 'div'))}
        </OrderedListItem>
      )
    }
    return <></>
  }

  return (
    <SectionListLayout styles={{ gap: 0 }}>
      <OverviewSection 
        images={title?.images.map(image => image.url) || []} 
        name={parse(title?.title)} 
        shortDesc={title?.desc && title?.desc !== '' && parse(title?.desc)}
        containerStyle={{ backgroundColor: 'transparent', py: { xs: 2.5, md: 5 } }}
      />
      <CustomSectionContainer variant="light" containerStyle={{ pt: 0, pb: 5 }} contentStyle={{ gap: 5 }}>
        {sectionList.map((section, index) => {
          if (section.children) {
            return (
              <CustomSectionContainer 
                key={index}
                withHeading
                heading={section.name !== '' && parse(section.name)}
                containerStyle={{ px: { xs: '16px', md: '20px' }}}
                headingStyle={{ textAlign: 'center' }}
              >
                <Grid container spacing={2.5}> 
                  {section?.children && section?.children.map((child, index) => (
                    <Grid key={index} item xs={12} md={Children.count(parse(child.definition)) === 2 && 6}>
                      <ItemWithImage
                        image={child.images[0].url} 
                        text={parse(child.definition, parsingOptions)}
                        style={{ p: { xs: 1.25 } }}
                        textStyle={[Children.count(parse(child.definition)) !== 2 && { height: { xs: 'fit-content' } }]}
                      />
                    </Grid>
                  ))}                                                                                                         
                </Grid> 
              </CustomSectionContainer>
            );
          }
          return (
            <CustomSectionContainer 
              key={index}
              withHeading
              heading={section.name !== '' && parse(section.name)}
              containerStyle={{ p: { xs: '0 16px', md: '0 20px' }, bgcolor: 'transparent' }}
              headingStyle={{ textAlign: 'center' }}
            >
              {parse(section.definition, parsingOptions)}
            </CustomSectionContainer>
          );
        })}
      </CustomSectionContainer>
    </SectionListLayout>
  );
}

export default MouthCare;
