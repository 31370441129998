import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { styled, SxProps } from "@mui/system";
import parse, { domToReact, Element, HTMLReactParserOptions } from "html-react-parser";

import OverviewSection from "./OverviewSection.web";
import ItemWithImage from "./ItemWithImage.web";
import PlainTextItem from "./PlanTextItem.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";

import { IContentData, ISectionData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const DivContainer = styled(Box)({
  borderRadius: "8px",
  border: "1px solid #D6DCD5", 
  background: "#FFF", 
  padding: "20px",
})

const webStyles = {
  alertContainer: {
    flexBasis: '100%',
    border: '2px dashed #F87171',
    backgroundColor:  '#FEF2F2',  
  }
}

const Constipation = ({ data }: { data: IContentData }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          id: group[1][0].id,
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  const getParsingOption = (listItemStyles?: SxProps) => {
    const parsingOptions: HTMLReactParserOptions = {
      trim: true,
      replace(domNode) {
          if (!(domNode instanceof Element)) return;
          const replacementByName: { [key: string]: JSX.Element } = {
            "ul": <>{domToReact(domNode.children, parsingOptions)}</>,
            "li": <PlainTextItem textStyle={{ ...listItemStyles }}>{domToReact(domNode.children)}</PlainTextItem>,
          };
          return replacementByName[domNode.name] || <>{domToReact(domNode.children, parsingOptions)}</>;
      },
    }
    return parsingOptions
  }

  return (
    <SectionListLayout>
      <OverviewSection 
        images={title?.images.map(image => image.url) || []} 
        name={parse(title?.title)} 
        shortDesc={title?.desc && title?.desc !== '' && parse(title?.desc)}
        containerStyle={{ p: { xs: '20px 16px', md: '40px 130px' } }}
      />
      {sectionList.map((section, index) => {
        if (section.children && section.children.length > 0 && sectionList[index + 1] && sectionList[index + 1]?.children) {
          return (<>
            {[...Array(2).keys()].map((num) => (
              <CustomSectionContainer
                key={index + num}
                withHeading
                heading={parse(sectionList[index + num].name)}
                headingStyle={{ textAlign: 'center' }}
                containerStyle={[
                  num !== 0 && { backgroundColor:  '#FEF2F2', border: '2px dashed #F87171' }, 
                  { py: { xs: 2.5, md: 5 } }
                ]}
              >
                <Grid container spacing={{ xs: 2, md: 2.5 }}>
                  {sectionList[index + num] && sectionList[index + num]?.children && sectionList[index + num]?.children?.map((child, index) => (
                    <Grid key={index} item xs={12} lg={num !== 0 ? 12 : 4}>
                      <ItemWithImage image={child.images[0]?.url || ''} text={parse(child.definition)} />
                    </Grid>
                  ))}
                </Grid>
              </CustomSectionContainer>
            ))}
          </>)
        }
        if (section.id && sectionList[index + 1] && sectionList[index + 1]?.id && sectionList[index + 2] && sectionList[index + 2]?.id) {
          return (
            <CustomSectionContainer 
              key={index}
              containerStyle={{ py: { xs: 2.5, md: 5 } }} 
              contentStyle={{ flexDirection: "row", flexWrap: "wrap" }}
            >
              {[...Array(3).keys()].map((num) => {
                if (num === 2) {
                  return (
                    <CustomSectionContainer
                      key={index + num}
                      containerStyle={{ padding: { xs: 2.5 }, ...webStyles.alertContainer }}
                      contentStyle={{ gap: 1.5 }}
                    >
                      {parse(sectionList[index + num].definition, getParsingOption({ fontWeight: '700' }))}
                    </CustomSectionContainer>
                  )
                }
                return (
                  <CustomSectionContainer
                    key={index + num}
                    withHeading
                    heading={parse(sectionList[index + num].name)}
                    containerStyle={{ width: { xs: '100%', md: 'calc(50% - 10px)' }, padding: { xs: 0 } }}
                    contentStyle={{ width: '100%', gap: { xs: 2, md: 2.5 } }}
                  >
                    {parse(sectionList[index + num].definition, {
                      replace(domNode) {
                        if (domNode instanceof Element && domNode.name === 'ul') {
                          return <>{domToReact(domNode.children, {
                            replace(child) {
                              if (child instanceof Element && child.name === 'li') {
                                return <>{domToReact(child.children, {
                                    replace(childNode) {
                                      if (childNode instanceof Element && childNode.name === 'div') {
                                        return (
                                          <DivContainer>
                                            <PlainTextItem>{domToReact(childNode.children)}</PlainTextItem>
                                          </DivContainer>
                                        )
                                      }
                                    },
                                  })}</>
                                }},
                          })}</>
                        }},
                    })}
                  </CustomSectionContainer>)
              })}
            </CustomSectionContainer>)} 
      })}
    </SectionListLayout>
  );
}

export default Constipation;
