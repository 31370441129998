import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { SxProps } from "@mui/system";
import parse, { domToReact, Element, HTMLReactParserOptions } from "html-react-parser";

import OverviewSection from "./OverviewSection.web";
import ItemWithImage from "./ItemWithImage.web";
import PlainTextItem from "./PlanTextItem.web";
import { CustomSectionContainer, SectionListLayout, SubcatContainer } from "./CustomContainer.web";
import { checkTagExisted } from "./ReactHtmlParser";

import { IContentData, ISectionData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const webStyles = {
  speacialHeadingContainer: {
    marginBottom: '20px',
    padding: '10px', 
    bgcolor: '#D6DCD5', 
    textAlign: 'center',
    '@media (min-width: 900px)': {
      minHeight: '116px',
    }
  }
}

const HospiceExpectation = ({ data }: { data: IContentData }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const getParsingOptions = (style?: { listItemStyle?: SxProps }) => {
    const parsingOptions: HTMLReactParserOptions = {
      trim: true,
      replace(domNode) {
        if (!(domNode instanceof Element)) return;
        const replacementByName: { [key: string]: JSX.Element } = {
            "ul": <>{domToReact(domNode.children, parsingOptions)}</>,
            "li": <PlainTextItem style={{ ...style?.listItemStyle }}>{domToReact(domNode.children)}</PlainTextItem>,
        };
        if (!replacementByName[domNode.name]) return;
        return replacementByName[domNode.name];
      },
    }
    return parsingOptions;
  }

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          id: group[1][0].id,
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  return (
    <SectionListLayout styles={{ gap: '10px' }}>
      <OverviewSection 
        images={title?.images.map(image => image.url) || []} 
        name={parse(title?.title)} 
        shortDesc={title?.desc && title?.desc !== '' && parse(title?.desc)}
      />
      {sectionList.map((section, index) => {
        if (section.children) {
          return (
            <CustomSectionContainer key={index} containerStyle={{ py: { xs: 2.5, md: 5 } }}>
              <Grid container spacing={2.5}>
                {section.children.map((child, index) => (
                  <Grid key={index} item xs={12}>
                    <ItemWithImage 
                      key={`grid-item-${index}`} 
                      image={child.images[0].url || ''} 
                      text={parse(child.definition)} imageSize={120}
                    />
                  </Grid>
                ))}
              </Grid>
            </CustomSectionContainer>
          );
        }
        if (section.id && checkTagExisted(section.name, 'div') && sectionList[index + 1] && sectionList[index + 1]?.id 
          && checkTagExisted(sectionList[index + 1]?.name, 'div')) {
            return (
              <CustomSectionContainer 
                key={index} 
                containerStyle={{  py: { xs: 2.5, md: 5 } }} 
                contentStyle={{ width: '100%', flexDirection: 'row', flexWrap: 'wrap', gap: '20px' }}
              >
                {[...Array(2).keys()].map((num) => 
                  <SubcatContainer
                    key={num}
                    withHeading
                    heading={parse(sectionList[index + num].name, {
                      ...getParsingOptions(),
                      replace(domNode) {
                        if (domNode instanceof Element && domNode.name === 'div') {
                          return <>{domToReact(domNode.children)}</>;
                        }
                      },
                    })}
                    containerStyle={{ my: 0, flexBasis: { xs: '100%', lg: 0 }, px: { xs: 0, md: 2.5 }, gap: 0 }}
                    headingStyle={webStyles.speacialHeadingContainer}
                  >
                    {parse(sectionList[index + num].definition, getParsingOptions({ listItemStyle: { p: { xs: 1.25, md: '10px 20px' } } }))}
                  </SubcatContainer>)}
              </CustomSectionContainer>
            );
        }
        if (section.id && !checkTagExisted(section.name, 'div') && sectionList[index + 1] && sectionList[index + 1]?.id 
          && sectionList[index + 1]?.name !== '' && !checkTagExisted(sectionList[index + 1]?.name, 'div')) {
            return (
              <CustomSectionContainer 
                key={index}
                containerStyle={{ py: { xs: 2.5, md: 5 } }} 
                contentStyle={{ flexDirection: 'row', flexWrap: 'wrap', gap: '20px' }}
              >
                {[...Array(2).keys()].map((num) => 
                  <SubcatContainer
                    key={num}
                    withHeading
                    heading={parse(sectionList[index + num].name)}
                    containerStyle={{ flexBasis: { xs: '100%', lg: 0 }, my: 0, px: 2.5, gap: 0 }}
                    headingStyle={{ mb: 2.5, textAlign: 'center' }}
                  >
                    {parse(sectionList[index + num].definition, getParsingOptions({ listItemStyle: { p: { xs: 1.25, md: '10px 20px' } } }))}
                  </SubcatContainer>)}
              </CustomSectionContainer>
            );
        }
        if (section.id && section.name === '') {
          return (
            <CustomSectionContainer 
              key={index}
              withHeading={section.name !== ''} 
              heading={parse(section.name)}
              containerStyle={[
                index === sectionList.length - 1 && { bgcolor: '#FEF2F2', border: '2px dashed #F87171', p: { xs: 2.5, md: '20px 150px' } }
              ]}
            >
              {parse(section.definition, getParsingOptions())}
            </CustomSectionContainer>
          );
        }
      })}
    </SectionListLayout>
  );
}

export default HospiceExpectation;
